import { Modal } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateCalendarEvent } from "common/hooks/useCreateCalendarEvent";
import { useFetchCategories } from "modules/admin/hooks/Calendar/useFetchCategories";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export const CreateEventModal = ({ isOpen, onClose }) => {
  const { data } = useFetchCategories();
  const { requestCreateCalendarEvent } = useCreateCalendarEvent();
  const { invalidateQueries } = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    setIsSubmitting(true);
    console.log(data);
    const parsedInputs = {
      name: data.name,
      start: data.start,
      end: data.end,
      day: data.day,
      categoryId: data.category,
      timeZone: data.timezone,
      description: data.description,
      images: Array.from(data.images),
    };
    await requestCreateCalendarEvent({
      calendarEventData: {
        ...parsedInputs,
        onSuccess: async () => {
          setIsSubmitting(false);
          toast.success("Event created successfully.", {
            toastId: "create-event",
          });
          invalidateQueries("calendarEvents");
        },
        onError: async () => {
          setIsSubmitting(false);
          toast.error("Failed to create event.", {
            toastId: "create-event-error",
          });
        },
      },
    });
  };
  return (
    <Modal open={isOpen} onClose={onClose}>
      <form
        className="bg-white p-4 w-full max-w-[900px] mx-auto mt-40"
        onSubmit={handleSubmit(submit)}
      >
        <div className="grid grid-cols-8 gap-x-2 gap-y-4">
          <div className="flex flex-col gap-2 col-span-8 md:col-span-4">
            <label className="font-semibold">EVENT NAME</label>
            <input
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              placeholder="Event name..."
              {...register("name", { required: true })}
            />
            <span className="text-red-500 text-sm">{errors.name && "Event name is required"}</span>
          </div>
          <div className="flex flex-col gap-2 col-span-8 md:col-span-4">
            <label className="font-semibold">DESCRIPTION</label>
            <textarea
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              placeholder="Event description..."
              {...register("description", { required: true })}
            />
            <span className="text-red-500 text-sm">
              {errors.description && "Description is required"}
            </span>
          </div>
          <div className="flex flex-col gap-2 col-span-8 md:col-span-2">
            <label className="font-semibold">START TIME</label>
            <input
              type="time"
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              {...register("start", { required: true })}
            />
            <span className="text-red-500 text-sm">{errors.start && "Start time is required"}</span>
          </div>
          <div className="flex flex-col gap-2 col-span-8 md:col-span-2">
            <label className="font-semibold">END TIME</label>
            <input
              type="time"
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              {...register("end", { required: true })}
            />
            <span className="text-red-500 text-sm">{errors.end && "End time is required"}</span>
          </div>
          <div className="flex flex-col gap-2 col-span-8 md:col-span-4">
            <label className="font-semibold">DATE</label>
            <input
              type="date"
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              {...register("day", { required: true })}
            />
            <span className="text-red-500 text-sm">{errors.day && "Date is required"}</span>
          </div>
          <div className="flex flex-col gap-2 col-span-8 md:col-span-4">
            <label className="font-semibold">CATEGORY</label>
            <select
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              {...register("category", { required: true })}
            >
              {data?.categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <span className="text-red-500 text-sm">
              {errors.category && "Category is required"}
            </span>
          </div>
          <div className="flex flex-col gap-2 col-span-8 md:col-span-4">
            <label className="font-semibold">TIME ZONE</label>
            <select
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              {...register("timezone", { required: true })}
            >
              <option value="GMT">GMT</option>
            </select>
            <span className="text-red-500 text-sm">
              {errors.timezone && "Timezone is required"}
            </span>
          </div>
          {/* Images */}
          <div className="flex flex-col gap-2 col-span-8 md:col-span-4">
            <label className="font-semibold">IMAGES</label>
            <input
              type="file"
              multiple
              className="border border-[#161C24] rounded-sm w-full px-2 py-1"
              {...register("images")}
            />
            <span className="text-red-500 text-sm">{errors.images && "Images are required"}</span>
          </div>
        </div>

        <div className="flex justify-end gap-x-2 mt-4">
          <button
            type="button"
            className="border-none text-[#808080] font-semibold px-4 py-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="border bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2"
          >
            {isSubmitting ? "Creating..." : "Create"}
          </button>
        </div>
      </form>
    </Modal>
  );
};
