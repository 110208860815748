import { SentryService } from "./SentryErrorCapture";
import axios from "axios";

export const onUpdateUserStatus = async ({ data, signature }) => {
  try {
    const updatedPackageResponse = await axios.put(
      `${process.env.REACT_APP_AWS_API_URL}/contracts/fg/userStatus`,
      {
        ...data,
        signature,
      },
    );
    if (updatedPackageResponse) {
      return updatedPackageResponse.data;
    }
  } catch (error) {
    // SentryService("onUpdateUserStatus", "onUpdateUserStatus", error);
    // console.debug(error);
    throw new Error(
      error.response
        ? error.response.data.error.reason ||
          error.response.data.error.shortMessage ||
          error.response.data.error
        : error
    );
  }
};
