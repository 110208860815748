export function convertTimestampToSpanishDate(timestamp) {
  const date = new Date(parseInt(timestamp));

  const readableDate = date.toLocaleString("es-ES", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return readableDate;
}
