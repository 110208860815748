import { useQuery } from "@tanstack/react-query";
import { useBidsService } from "modules/admin/services/Bids";

export const useFetchBidsHistory = (queryKey = "bidsHistory", product_id) => {
  const { fetchBidsHistory } = useBidsService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchBidsHistory(product_id),
    select: (data) => data.data,
  });

  return { ...queryResults };
};
