import { GenericList } from "common/components";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect, useState } from "react";
import { useFetchUsers } from "../hooks/Users/useFetchUsers";
import { SkeletonTable } from "../components/ui/SkeletonTable";
import { useNavigate } from "react-router-dom";

export const UsersPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const {
    isPending,
    data: usersData,
    params,
    setParams,
  } = useFetchUsers("users", { page: 1, limit: 10, search: "" });

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (!usersData || !usersData.users) return;
    setUsers(
      usersData?.users.map((user) => {
        return {
          id: user.id,
          username: user.username,
          wallet: user.id,
          sponsor: user.sponsor,
          actions: (
            <div className="flex gap-x-2">
              <button
                className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => { window.scrollTo(0, 0);  navigate(`/admin/users/detail/${user.id}`)}}
              >
                See Details{" "}
                
              </button>
            </div>
          ),
        };
      })
    );
  }, [usersData]);
  const columns = {
    username: { header: "Username" },
    wallet: { header: "Wallet" },
    sponsor: { header: "Sponsor" },
    actions: {
      header: "Actions",
    },
  };

  const headerOptions = (
    <div className="flex w-full justify-between">
      {/* <select className="select-search-bar p-2 w-1/5 border border-[#161C24] border-r-0">
        <option value="all">All</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select> */}
      <div className="flex-1 border border-[#161C24]">
        <input
          type="text"
          placeholder="Search"
          className="p-2 w-3/4"
          value={searchInput}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setParams({ ...params, search: searchInput, page: 1 });
            }
          }}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <button
          className="bg-[#1840CE] text-white px-4 py-2 w-1/4"
          onClick={() => {
            setParams({ ...params, search: searchInput, page: 1 });
          }}
        >
          Search
        </button>
      </div>
    </div>
  );

  return (
    <AdminLayout>
      <div className="container mx-auto">
        <div className="bg-white px-8">
          <div className="w-full">
            <h1 className="text-xl font-semibold px-4 pt-8">Users</h1>
          </div>
          <GenericList
            data={users}
            columns={columns}
            headerOptions={headerOptions}
            isLoading={isPending}
          />
          <div className="w-full justify-center flex p-5">
            {usersData?.pagination && (
              <div className="pb-52">
                <div className="flex">
                  {usersData?.pagination.prev_page && (
                    <button
                      className="text-[16px] text-[#808080] font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page - 1 });
                      }}
                    >{` PREVIOUS `}</button>
                  )}
                  {usersData?.pagination.page - 1 > 0 && (
                    <button
                      className="text-[16px] text-dark font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page - 1 });
                      }}
                    >{` ${usersData?.pagination.page - 1} `}</button>
                  )}
                  <span className="text-[16px] text-dark font-bold">
                    {usersData?.pagination.page}
                  </span>
                  {usersData?.pagination.page + 1 <= usersData?.pagination.total_pages && (
                    <button
                      className="text-[16px] text-dark font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page + 1 });
                      }}
                    >{` ${usersData?.pagination.page + 1} `}</button>
                  )}
                  {usersData?.pagination.next_page && (
                    <button
                      className="text-[16px] text-[#808080] font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: usersData?.pagination.page + 1 });
                      }}
                    >{` NEXT `}</button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
