import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useTeamPerformanceService = () => {
    return {
        async fetchTeamPerformance(userAddress) {
            const response = await axios.get(`${API}/contracts/fg/user/teamPerformance`, {
                params: {
                    userId: userAddress,
                },
            });
            return response.data;
        },
    };
};
