import { useEffect, useState } from "react";
import { GenericList } from "common/components";
import AdminLayout from "layouts/AdminLayout";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDeleteBid } from "../hooks";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useFetchProducts } from "../hooks/Products/useFetchProducts";

export const PastProductListPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productToDelete, setProductToDelete] = useState({});
  const { requestDeleteBid } = useDeleteBid();
  const { address } = useAccount();
  const {
    data: productsData,
    params,
    setParams,
    isPending,
  } = useFetchProducts("past-products", { status: [3, 2], search: "", page: 1, limit: 10 });

  const handleDelete = async () => {
    await requestDeleteBid({
      bidData: {
        userId: address,
        id: productToDelete.id,
        onSuccess: async () => {
          toast.success("Product deleted successfully.");
        },
        onError: async () => {
          toast.error("Failed to delete product.");
        },
      },
    });
    toast.success("Product deleted successfully.");
    setIsModalOpen(false);
    setProducts(products.filter((product) => product.id !== productToDelete.id));
  };

  useEffect(() => {
    if (productsData && productsData?.items) {
      setProducts(
        productsData.items.map((item) => ({
          id: item.id,
          name: item.info.name,
          price: item.auctionPrice ? `${item.auctionPrice / 1000000} USDT` : item.auctionPrice,
          category: item.category,
          actions: (
            <div className="flex gap-x-2">
              <button
                className="border bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => {
                  navigate(`/admin/products/bids/${item.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                See bids
              </button>
              <button
                className="border bg-[#87BAD7] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => navigate(`/admin/products/edit/${item.id}`)}
              >
                Edit
              </button>
              <button
                className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setProductToDelete(item);
                }}
              >
                Delete
              </button>
            </div>
          ),
        }))
      );
    }
  }, [navigate, productsData]);

  const columns = {
    name: { header: "Product Name" },
    price: { header: "Price" },
    category: { header: "Category" },
    actions: {
      header: "Actions",
    },
  };

  const headerOptions = (
    <div className="flex w-full justify-between">
      {/* <select className="select-search-bar p-2 w-1/5 border border-[#161C24] border-r-0">
        <option value="all">All</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select> */}
      <div className="flex-1 border border-[#161C24]">
        <input
          type="text"
          placeholder="Search"
          className="p-2 w-3/4"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setParams({ ...params, search: searchInput, page: 1 });
            }
          }}
        />
        <button
          className="bg-[#1840CE] text-white px-4 py-2 w-1/4"
          onClick={() => {
            /* clear pagination */
            setParams({ ...params, search: searchInput, page: 1 });
          }}
        >
          Search
        </button>
      </div>
      <button
        className="bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2 ml-2"
        onClick={() => navigate("/admin/products/create")}
      >
        Create New Product
      </button>
    </div>
  );

  return (
    <AdminLayout>
      <div className="container mx-auto">
        <div className="bg-white px-8">
          <div className="w-full">
            <h1 className="text-2xl px-4 pt-8">Product List</h1>
          </div>
          <GenericList
            data={products}
            columns={columns}
            headerOptions={headerOptions}
            isLoading={isPending}
          />
          <div className="w-full justify-center flex p-5">
            {productsData?.pagination && (
              <div className="pb-52">
                <div className="flex">
                  {productsData?.pagination.prev_page && (
                    <button
                      className="text-[16px] text-[#808080] font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: productsData?.pagination.page - 1 });
                      }}
                    >{` PREVIOUS `}</button>
                  )}
                  {productsData?.pagination.page - 1 > 0 && (
                    <button
                      className="text-[16px] text-dark font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: productsData?.pagination.page - 1 });
                      }}
                    >{` ${productsData?.pagination.page - 1} `}</button>
                  )}
                  <span className="text-[16px] text-dark font-bold">
                    {productsData?.pagination.page}
                  </span>
                  {productsData?.pagination.page + 1 <= productsData?.pagination.total_pages && (
                    <button
                      className="text-[16px] text-dark font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: productsData?.pagination.page + 1 });
                      }}
                    >{` ${productsData?.pagination.page + 1} `}</button>
                  )}
                  {productsData?.pagination.next_page && (
                    <button
                      className="text-[16px] text-[#808080] font-bold mx-10"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setParams({ ...params, page: productsData?.pagination.page + 1 });
                      }}
                    >{` NEXT `}</button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-[400px] mx-auto bg-white rounded-md mt-20">
          <h2 className="text-2xl p-4 text-red-500">Delete product</h2>
          <p className="p-4">Are you sure you want to delete this product?</p>
          <div className="flex justify-end gap-x-2 p-4">
            <button
              className="border border-dark text-dark font-bold px-4 py-2 rounded"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="border border-red-500 text-red-500 font-bold px-4 py-2 rounded"
              onClick={() => handleDelete()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </AdminLayout>
  );
};
