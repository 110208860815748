import { onReadContract } from "../../../onReadContract";

export const userPointsFunctions = ({ userPointsContract, userPointsABI }) => {
  const userPointsCache = { userTotalPoints: {}, userQVPoints: {}, userPayments: {} };
  const readUserPoints = async ({ functionName, args }) => {
    return await onReadContract({
      contractAddress: userPointsContract,
      contractABI: userPointsABI,
      functionName,
      args,
    });
  };

  const getUserTotalPoints = async ({ address }) => {
    if (
      userPointsCache &&
      userPointsCache.userTotalPoints &&
      userPointsCache.userTotalPoints[address]
    ) {
      return userPointsCache.userTotalPoints[address];
    }
    const args = [address];
    const getUserTotalPointsResponse = await readUserPoints({
      functionName: "totalPointsMap",
      args,
    });

    console.debug("getUserTotalPoints: ", getUserTotalPointsResponse, address);
    if (userPointsCache && userPointsCache.userTotalPoints) {
      userPointsCache.userTotalPoints[address] = {
        leftBranch: Number(getUserTotalPointsResponse[0]),
        rightBranch: Number(getUserTotalPointsResponse[1]),
        unilevel: Number(getUserTotalPointsResponse[2]),
      };
    }
    return {
      leftBranch: Number(getUserTotalPointsResponse[0]),
      rightBranch: Number(getUserTotalPointsResponse[1]),
      unilevel: Number(getUserTotalPointsResponse[2]),
    };
  };
  const getUserPoints = async ({ address, start, limit }) => {
    const args = [address, start, limit];
    const getUserTotalPointsResponse = await readUserPoints({
      functionName: "getUserPoints",
      args,
    });

    console.debug("getUserTotalPoints: ", getUserTotalPointsResponse, address);
    return getUserTotalPointsResponse.map((points) => ({
      id: Number(points[0]),
      leftBranch: Number(points[1]),
      rightBranch: Number(points[2]),
      unilevel: Number(points[3]),
      registrationDate: Number(points[4]),
    }));
  };
  const getUserActivePoints = async ({ address, start, limit }) => {
    const args = [address, start, limit];
    const getUserTotalPointsResponse = await readUserPoints({
      functionName: "getUserActivePoints",
      args,
    });

    console.debug("getUserTotalPoints: ", getUserTotalPointsResponse, address);
    return getUserTotalPointsResponse.map((points) => ({
      id: Number(points[0]),
      leftBranch: Number(points[1]),
      rightBranch: Number(points[2]),
      unilevel: Number(points[3]),
      registrationDate: Number(points[4]),
    }));
  };
  const getUserQVPoints = async ({ address }) => {
    if (userPointsCache && userPointsCache.userQVPoints && userPointsCache.userQVPoints[address]) {
      return userPointsCache.userQVPoints[address];
    }
    const args = [address];
    const getUserQVPointsResponse = await readUserPoints({
      functionName: "unilevelQVMap",
      args,
    });

    console.debug("getUserQVPointsResponse: ", getUserQVPointsResponse, address);
    if (userPointsCache && userPointsCache.userQVPoints) {
      userPointsCache.userQVPoints[address] = Number(getUserQVPointsResponse);
    }
    return Number(getUserQVPointsResponse);
  };
  const getUserPayments = async ({ address }) => {
    if (userPointsCache && userPointsCache.userPayments && userPointsCache.userPayments[address]) {
      return userPointsCache.userPayments[address];
    }
    const args = [address];
    const getUserPaymentsResponse = await readUserPoints({
      functionName: "getPaymentsMap",
      args,
    });

    console.debug("getUserPaymentsResponse: ", getUserPaymentsResponse, address);
    const getUserPaymentsResponseParsed = {
      total: Number(getUserPaymentsResponse[0]),
      affiliates: getUserPaymentsResponse[1].map((affiliate) => {
        return {
          from: affiliate.from,
          parentId: address,
          packageId: Number(affiliate.packageId),
          amount: Number(affiliate.amount),
          date: Number(affiliate.date),
        };
      }),
    };
    if (userPointsCache && userPointsCache.userPayments) {
      userPointsCache.userPayments[address] = getUserPaymentsResponseParsed;
    }
    console.log("getUserPaymentsResponseParsed", getUserPaymentsResponseParsed)
    return getUserPaymentsResponseParsed;
  };

  const getLeadershipPool = async () => {
    const args = [];
    const getLeadershipPoolResponse = await readUserPoints({
      functionName: "leadershipPool",
      args,
    });

    console.debug("getLeadershipPoolResponse: ", getLeadershipPoolResponse);
    return Number(getLeadershipPoolResponse);
  };

  return {
    getUserTotalPoints,
    getUserPoints,
    getUserActivePoints,
    getUserQVPoints,
    getUserPayments,
    getLeadershipPool,
  };
};
