export function parseCSVToJSON(csvText, delimiter = ',') {
    const rows = csvText.split('\n');

    const headers = rows.shift().split(delimiter).map(header => header.replace(/"/g, '').trim());

    const jsonArray = rows.map(row => {
        const values = row.split(delimiter);

        const entry = headers.reduce((obj, header, index) => {
            obj[header] = values[index].replace(/"/g, '').trim();
            return obj;
        }, {});

        return entry;
    });

    return jsonArray;
}