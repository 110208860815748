import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const onTotalCommissionsService = () => {
  return {
    async fetchTotalCommissions(userAddress) {
      const response = await axios.get(`${API}/contracts/comm/user/commissions`, {
        params: { userId: userAddress },
      });
      return response.data;
    },
  };
};
