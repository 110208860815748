import { useNavigate } from "react-router-dom";

export const ProductCard = ({ favButtonHandler, cardInfo, autoBuy, addBid, followed }) => {
  const navigate = useNavigate();
  const currentDate = new Date().getTime();
  const pinataGatewayKey = `?pinataGatewayToken=${process.env.REACT_APP_PINATA_GATEWAY_KEY}`;
  const getSign = (status, biddersCount, maxBidders) => {
    if (status == 2) return "Auction ended";
    if (status == 3) return "sold";
    if (biddersCount >= maxBidders) return "no new biders";
  };
  (cardInfo = {
    ...cardInfo,
    bidNow:
      cardInfo.status == 1 && cardInfo.startDate <= currentDate,
    sold: cardInfo.status == 3,
    startingSoon: cardInfo.status == 1 && cardInfo.startDate > currentDate,
    buyNow: cardInfo.status > 0,
    isFav: false,
    sign: getSign(cardInfo.status, cardInfo.biddersCount, cardInfo.maxBidders),
  });
    // console.debug({ cardInfo });
  return (
    <div className="relative flex flex-col items-center justify-between text-xs border-2 border-black w-52 max-w-52 overflow-hidden min-h-80 pt-4 gap-1 cursor-pointer">
      <div className="absolute right-2 top-2">
        <svg
          onClick={() => {
            favButtonHandler({ itemId: cardInfo?.id });
          }}
          className="scale-150 cursor-pointer"
          width="12"
          height="11"
          viewBox="0 0 12 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.42 1.30506C10.1646 1.04956 9.86141 0.846881 9.52768 0.7086C9.19395 0.570319 8.83624 0.499146 8.475 0.499146C8.11376 0.499146 7.75605 0.570319 7.42232 0.7086C7.08859 0.846881 6.78538 1.04956 6.53 1.30506L6 1.83506L5.47 1.30506C4.95415 0.789212 4.25451 0.499413 3.525 0.499413C2.79548 0.499413 2.09584 0.789212 1.58 1.30506C1.06415 1.8209 0.774353 2.52054 0.774353 3.25006C0.774353 3.97957 1.06415 4.67921 1.58 5.19506L2.11 5.72506L6 9.61506L9.89 5.72506L10.42 5.19506C10.6755 4.93968 10.8782 4.63647 11.0165 4.30274C11.1547 3.96901 11.2259 3.6113 11.2259 3.25006C11.2259 2.88881 11.1547 2.53111 11.0165 2.19738C10.8782 1.86365 10.6755 1.56044 10.42 1.30506Z"
            fill={followed ? "red" : "white"}
            stroke="#161C24"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        className=""
        onClick={() => (cardInfo ? navigate(`/app/store/product/${cardInfo.id}`) : undefined)}
      >
        <p>
          PRODUCT #{cardInfo?.id || 0} {cardInfo?.info?.name || "LOREM IPSUM"}
        </p>
      </div>
      {cardInfo.sign !== undefined && (
        <div
          className={`absolute h-6 flex items-center justify-center ${
            cardInfo.sign === "sold" ? "bg-brick-red" : "bg-baby-blue"
          } font-bold w-[110%] rotate-[20deg] top-1/3 border-y-2 border-black`}
        >
          {cardInfo.sign.toUpperCase()}
        </div>
      )}
      <div
        className="max-h-[125px] max-w-40 flex"
        onClick={() => (cardInfo ? navigate(`/app/store/product/${cardInfo.id}`) : undefined)}
      >
        <img
          src={
            cardInfo?.info?.images
              ? `${cardInfo.info.images[0]}${pinataGatewayKey}`
              : "https://via.placeholder.com/150"
          }
          className="w-full object-contain"
        />
      </div>
      <div className="flex flex-col items-center">
        <p className={cardInfo.bidNow || cardInfo.sold || cardInfo.buyNow ? "text-green-700" : ""}>
          {cardInfo.bidNow
            ? `$${cardInfo.currentPrice ? cardInfo.currentPrice / 10 ** 6 : 0}`
            : cardInfo.startingSoon && `Starts at ${new Date(cardInfo.startDate).toLocaleString()}`}
        </p>
        <p className="text-xs p-1 text-ellipsis flex">
          {cardInfo.bidNow
            ? cardInfo.lastBid
              ? `Bidder: ${String(cardInfo.lastBid.bidder).substring(0, 8)}...`
              : "No bids yet"
            : cardInfo.startingSoon && "Bid during the last 10 seconds."}
        </p>
        <p className={cardInfo.bidNow || cardInfo.sold || cardInfo.buyNow ? "text-orange-400" : ""}>
          {cardInfo.bidNow && getHoursMinutesAndSeconds(cardInfo.endDate)}
          {cardInfo.startingSoon && getHoursMinutesAndSeconds(cardInfo.startDate)}
        </p>
      </div>
      <div className="w-full">
        {cardInfo.bidNow && (
          <div
            className="bg-highlight-secondary w-full text-center text-sm font-bold py-2"
            onClick={() => {
              addBid(cardInfo.id);
            }}
          >
            BID NOW
          </div>
        )}
        {cardInfo.startingSoon && (
          <div className="bg-custom-yellow w-full text-center text-sm font-bold py-2">
            STARTING SOON
          </div>
        )}
        {cardInfo.sold && (
          <div className="bg-clair-gray w-full text-center text-sm font-bold py-2">SOLD</div>
        )}
        {null && cardInfo.buyNow && (
          <div
            className="bg-baby-blue w-full text-center text-sm py-2"
            onClick={() => {
              autoBuy(cardInfo.id);
            }}
          >
            Buy now for ${cardInfo ? cardInfo.autoBuyPrice / 10 ** 6 : `0.00`}
          </div>
        )}
      </div>
    </div>
  );
};

//! I would replace this with a custom commponent that acts as a countdown
const getHoursMinutesAndSeconds = (endTime) => {
  const currentTime = new Date().getTime();
  const difference = endTime - currentTime;
  // If the end time is already passed, return "00:00:00"
  if (difference <= 0) {
    return "00:00:00";
  }

  // Convert the difference from milliseconds to seconds
  const totalSeconds = Math.floor(difference / 1000);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format hours, minutes, and seconds to always have two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  // Return the formatted time as a string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
