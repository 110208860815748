import { CONSTANTS } from "common/services/rankAndCommissions/constants/constants";

const getUserPackage = async (addressToGet, contractFunctions) => {
  const userPackage = await contractFunctions.fortuneGoat.fortuneGoatUserPackage(addressToGet.id);
  const userWithPackage = { ...addressToGet, ...userPackage };
  if (userPackage && userPackage.packageId) {
    userWithPackage.totalPaid = Number(userPackage.totalPaid);
    userWithPackage.packageId = Number(userPackage.packageId);
  }
  return userWithPackage;
};

export const getUsersTotalPaid = async (addressess = [], packagesList, contractFunctions) => {
  console.log("getUsersTotalPaid", addressess);
  try {
    const userPackages = [];
    for (const currentAddress of addressess) {
      userPackages.push(await getUserPackage(currentAddress, contractFunctions));
    }
    return userPackages.filter((userPackage) => {
      if (!userPackage.packageId) return false;
      const pack = packagesList.filter(
        (packFromList) => packFromList.fullPackage.id === userPackage.packageId
      );
      if (
        CONSTANTS.CURRENT_DATE_TIME_IN_SECONDS >=
        pack[0].fullPackageExpirations.qualificationExpirationDays * 24 * 60 * 60 +
          (userPackage.registerDate || CONSTANTS.STAGE_ONE_LIMIT_DATE)
      ) {
        return false;
      }
      return true;
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};
