import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useCommissionsService = () => {
  return {
    async fetchCommissions(params) {
      const response = await axios.get(`${API}/contracts/comm/list`, {
        params,
      });
      return response.data;
    },
    async fetchCommissionsFiles(params) {
      const response = await axios.get(`${API}/contracts/comm/fileList`, {
        params,
      });
      return response.data;
    },
    async fetchCommissionsFile(data) {
      const response = await axios.post(`${API}/contracts/comm/fileList`, data, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );

      // Crear un enlace temporal para descargar el archivo
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${data?.date}.zip`); // Nombre del archivo

      // Añadir el enlace al DOM y simular el click
      document.body.appendChild(link);
      link.click();

      // Limpiar el DOM
      document.body.removeChild(link);

      return response;
    },
    async fetchCommissionsSummary() {
      const response = await axios.get(`${API}/contracts/comm/summary`);
      return response.data;
    },
  };
};
