import { useQuery } from "@tanstack/react-query";
import { onTotalCommissionsService } from "common/services/onTotalCommissionsService";

export const useFetchTotalCommissions = (queryKey = "totalCommissions", userAddress) => {
  const { fetchTotalCommissions } = onTotalCommissionsService();

  const queryResults = useQuery({
    queryKey: [queryKey],
    queryFn: () => fetchTotalCommissions(userAddress),
    select: (data) => data.data,
  });

  return { ...queryResults };
};
