import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const useBidsService = () => {
  return {
    async fetchBids(params) {
      const response = await axios.get(`${API}/contracts/pa/bids/list`, {
        params,
      });
      return response.data;
    },
    async fetchBidsHistory(product_id) {
      const response = await axios.get(`${API}/contracts/pa/bids/history`, {
        params: { itemId: product_id },
      });
      return response;
    },
  };
};
