import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteCalendarEvent } from "common/hooks";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

export const DeleteEventModal = ({ isOpen, onClose, activeEvent }) => {
  const { address } = useAccount();
  const { requestDeleteCalendarEvent } = useDeleteCalendarEvent();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    await requestDeleteCalendarEvent({
      calendarEventData: {
        userId: address,
        id: activeEvent.id,
        onSuccess: async () => {
          setIsSubmitting(false);
          toast.success("Event deleted successfully.", {
            toastId: "delete-event",
          });
          queryClient.invalidateQueries("calendarEvents");
          onClose();
        },
        onError: async () => {
          setIsSubmitting(false);
          toast.error("Failed to delete event.", {
            toastId: "delete-event-error",
          });
        },
      },
    });
  };
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="bg-white p-4 w-1/4 mx-auto mt-40">
        <h1>Are you sure you want to delete the event?</h1>
        <div className="flex justify-end gap-x-2 mt-4">
          <button
            className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="border bg-[#FF0000] text-white font-semibold px-4 py-2"
            onClick={handleDelete}
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            {isSubmitting ? "Deleting..." : "Delete"}
          </button>
        </div>
      </div>
    </Modal>
  );
};
