import { useQuery } from "@tanstack/react-query";
import { useCommissionsService } from "modules/admin/services/Commissions";

export const useFetchCommissionsFiles = (
  queryKey = "commissionsFiles",
  signature = null,
  startDate = new Date(new Date().setDate(new Date().getDate() - 30)),
  endDate = new Date(new Date().setDate(new Date().getDate() + 1))
) => {
  /* 
    page: "",
    limit: "",
    search: "",
   */
  const { fetchCommissionsFiles } = useCommissionsService();

  const queryResults = useQuery({
    queryKey: [queryKey, signature],
    queryFn: () => fetchCommissionsFiles({ signature, startDate, endDate }),
    enabled: !!signature,
    select: (data) => data.data,
  });

  return { ...queryResults };
};
