import { useQuery } from "@tanstack/react-query";
import { useTeamPerformanceService } from "common/services/teamPerformance";

export const useFetchTeamPerformance = (queryKey = "teamPerformance", userAddress) => {

    const { fetchTeamPerformance } = useTeamPerformanceService();

    return useQuery({
        queryKey: [queryKey],
        queryFn: () => fetchTeamPerformance(userAddress),
        select: (data) => data.data,
    });
};