import axios from "axios";

const API = process.env.REACT_APP_AWS_API_URL;

export const userPointsService = () => {
  return {
    async fetchUserPoints(userAddress) {
      const response = await axios.get(`${API}/contracts/comm/user/totalPoints`, {
        params: {
          userId: userAddress,
        },
      });
      return response.data;
    },
  };
};
