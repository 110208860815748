import { fortuneGoatFunctions } from "common/services/rankAndCommissions/utils/contractFunctions/fortuneGoat";
import { packagesFunctions } from "common/services/rankAndCommissions/utils/contractFunctions/packages";
import { getUsersTotalPaid } from "common/services/rankAndCommissions/utils/userCommissions/onGetRank/getUsersTotalPaid";
import { useCallback, useEffect, useState } from "react";

const {
  binaryFunctions,
} = require("common/services/rankAndCommissions/utils/contractFunctions/binary");
const binaryABI = require("../contracts/Binary.json");
const fgABI = require("../contracts/FortuneGoat.json");
const packagesABI = require("../contracts/Packages.json");

export const useGetActiveChildren = (address) => {
  const [activeChildren, setActiveChildren] = useState({
    left: [],
    right: [],
    leftCount: 0,
    rightCount: 0,
  });
  const [loading, setIsLoading] = useState(false);
  const getActiveChildren = useCallback(async (address) => {
    setIsLoading(true);
    try {
      const response = {
        left: [],
        right: [],
        leftCount: 0,
        rightCount: 0,
      };
      if (!address) {
        return setActiveChildren(response);
      }

      const packagesContract = process.env.REACT_APP_PACKAGES_CONTRACT_ADDRESS;
      const packagesFunctionsAPI = packagesFunctions({ packagesContract, packagesABI });
      const packagesList = await packagesFunctionsAPI.packagesList();
      if (!packagesList) {
        return setActiveChildren(response);
      }

      const binaryContract = process.env.REACT_APP_BINARY_CONTRACT_ADDRESS;
      const fgContract = process.env.REACT_APP_CONTRACT_ADDRESS;

      const binaryFunctionsAPI = binaryFunctions({ binaryContract, binaryABI });
      const FGFunctionsAPI = fortuneGoatFunctions({ fgContract, fgABI });
      const userHasSponsored = await binaryFunctionsAPI.binaryUsersSponsoredBy(address);
      const contractFunctions = {
        fortuneGoat: FGFunctionsAPI,
      };

      const sponsoredUsersPreFilter = await getUsersTotalPaid(
        userHasSponsored,
        packagesList,
        contractFunctions
      );

      const sponsoredUsers = sponsoredUsersPreFilter.filter(
        (sponsored) => sponsored.packageId != undefined
      );

      response.left = sponsoredUsers.filter((sponsored) => sponsored.sponsorBranch == 1);
      response.right = sponsoredUsers.filter((sponsor) => sponsor.sponsorBranch == 2);
      response.leftCount = response.left.length || 0;
      response.rightCount = response.right.length || 0;
      setActiveChildren(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    getActiveChildren(address);
  }, [address, getActiveChildren]);

  return { activeChildren };
};
