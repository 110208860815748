import AdminLayout from "layouts/AdminLayout";
import { useParams } from "react-router-dom";
import { useFetchBidsHistory } from "../hooks/Bids/useFetchBidsHistory";
import { parseCSVToJSON } from "common/util/parseCSVToJSON";
import { useEffect, useState } from "react";
import { convertTimestampToSpanishDate } from "common/util/convertTimestampToSpanishDate";
import { GenericList } from "common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSync } from "@fortawesome/free-solid-svg-icons";

export const SeeProductBidsPage = () => {
  const { id } = useParams();
  const { data, refetch, isRefetching } = useFetchBidsHistory(`bidsHistory-${id}`, id);
  const [bids, setBids] = useState([]);

  useEffect(() => {
    if (data) {
      setBids(parseCSVToJSON(data));
    }
  }, [data]);

  /* bids is an array of:
  {"bidNumber":"200","date":"1725040077494","username":"david","userId":"0x86cdbf17a5c603aca3b1babfd001ffd7d43b9639","itemId":"186","userBidsBefore":"3878","userBidsAfter":"3877","itemPriceBefore":"199","itemPriceAfter":"200","priceIncrement":"1"}
  */

  const columns = {
    bidNumber: { header: "ID" },
    date: { header: "Date" },
    username: { header: "Username" },
    userId: { header: "User address" },
    itemPriceBefore: { header: "Price Before" },
    itemPriceAfter: { header: "Price After" },
  };

  return (
    <AdminLayout>
      <div className="w-full bg-white">
        <div className="w-full px-8">
          <button className="text-dark font-bold pt-8" onClick={() => window.history.back()}>
            <FontAwesomeIcon icon={faArrowLeft} /> Regresar
          </button>
          <div className="flex gap-x-2 items-end pt-6">
            <h1 className="text-2xl">Bids History</h1>
            <button className="text-blue-500 font-bold" onClick={() => refetch()}>
              Refresh <FontAwesomeIcon icon={faSync} />
            </button>
          </div>
          <GenericList
            data={bids.map((bid) => ({ ...bid, date: convertTimestampToSpanishDate(bid.date) }))}
            columns={columns}
            headerOptions={() => null}
            isLoading={isRefetching}
          />
        </div>
      </div>
    </AdminLayout>
  );
};
