import { useRequestSignature } from "common/hooks/useRequestSignature";
import React, { useState, useEffect, useRef, createContext, useContext, useCallback } from "react";
import { useAccount } from "wagmi";
import { handleItemResponse, handleItemsResponse } from "./handlers/items";
import { handleUserResponse } from "./handlers/user";
import { handleBidsResponse, handleUserBidsResponse } from "./handlers/bids";
import { useQuery } from "@tanstack/react-query";
import { onGetCategoriesList } from "../services/onGetCategoriesList";
import { pennyAuctionConstants } from "../constants/pennyAuctionConstants";
import { toast } from "react-toastify";
import { handleAuctionEndResponse, handleAuctionWinResponse } from "./handlers/auctionEnd";
import { handleAutobuyResponse, handleUserAutobuyResponse } from "./handlers/autoBuy";
import { handleServerTimeResponse } from "./handlers/serverTime";

const AuctionContext = createContext(null);

export const useAuctionService = () => {
  const context = useContext(AuctionContext);
  if (!context) {
    throw new Error("useAuctionService must be used within an AuctionServiceProvider");
  }
  return context;
};
// const SORT_TYPES = {
//   "Best sellers": 0,
//   "A-Z": 1,
//   "Z-A": 2,
//   "Price-Low-High": 3,
//   "Price-High-Low": 4,
//   "Oldest-Newest": 5,
//   "Newest-oldest": 6,
//   "Bids-Low-High": 7,
//   "Bids-High-Low": 8,
// };

export const AuctionProvider = ({ children, initialValues = {} }) => {
  // console.debug({ initialValues });
  const { address, isConnected } = useAccount();
  const requestSignature = useRequestSignature();
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [items, setItems] = useState([]);
  const [itemDetails, setItemDetails] = useState({});
  const [user, setUser] = useState(null);
  const [category, setCategory] = useState(initialValues.category || null);
  const [search, setSearch] = useState(initialValues.search || null);
  const [itemId, setItemId] = useState(initialValues.itemId || null);
  const [status, setStatus] = useState(initialValues.status || null);
  const [page, setPage] = useState(initialValues.page || null);
  const [pagination, setPagination] = useState(null);
  const [sortType, setSortType] = useState(
    initialValues.sortType || pennyAuctionConstants.SORT_TYPES["Newest-oldest"]
  );
  const [serverTime, setServerTime] = useState({
    serverTime: 0,
    currentTime: new Date().getTime(),
    offset: 0,
  });
  const wsInstance = useRef(null);

  // Refs to hold the latest values
  const itemDetailsRef = useRef(itemDetails);
  const iddleIntervalRef = useRef();

  // Update ref when state changes
  useEffect(() => {
    itemDetailsRef.current = itemDetails;
  }, [itemDetails]);

  const {
    isFetching: isLoadingCategories,
    data: categoriesList,
    error: categoriesListError,
  } = useQuery({
    queryKey: [`categoriesList`],
    queryFn: async () => await onGetCategoriesList(),
    staleTime: 360000,
    refetchOnWindowFocus: false,
  });

  const getItems = useCallback(({ status, category, search, itemId, limit, page, sortType }) => {
    // console.debug("GetItems", { status, category, search, itemId, limit, page });
    if (wsInstance.current && wsInstance.current.readyState) {
      // if (!wsInstance.current.readyState) {
      //   setConnectionStatus("Disconnected");
      //   wsInstance.current = null;
      //   toast.error("Connection was lost, reconnecting...")
      //   return;
      // }
      const message = JSON.stringify({
        action: "items",
        ...(status ? { status: String(status) } : {}),
        ...(category ? { category: String(category) } : {}),
        ...(search ? { search: String(search) } : {}),
        ...(itemId ? { id: String(itemId) } : {}),
        ...(limit ? { limit: String(limit) } : { limit: String(15) }),
        ...(page ? { page } : { page: 1 }),
        ...(sortType
          ? { sortType }
          : { sortType: pennyAuctionConstants.SORT_TYPES["Newest-oldest"] }),
      });
      // console.debug(message);
      try {
        wsInstance.current.send(message);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const syncTimes = async () => {
    const message = JSON.stringify({ action: "serverTime" });
    try {
      wsInstance.current.send(message);
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = useCallback(() => {
    if (wsInstance.current && address && wsInstance.current.readyState) {
      // if (!wsInstance.current.readyState) {
      //   setConnectionStatus("Disconnected");
      //   wsInstance.current = null;
      //   toast.error("Connection was lost, reconnecting...")
      //   return;
      // }
      const userMessage = JSON.stringify({ action: "users", userId: address });
      // console.debug(userMessage);
      try {
        wsInstance.current.send(userMessage);
      } catch (error) {
        console.error(error);
      }
    }
  }, [address]);

  const getInfo = useCallback(() => {
    if (wsInstance.current && wsInstance.current.readyState) {
      // console.debug(wsInstance.current);
      // getItems({});
      if (address) getUser();
    }
  }, [getUser, address]);

  const addBid = async (itemId) => {
    if (wsInstance.current && address && wsInstance.current.readyState) {
      // if (!wsInstance.current.readyState) {
      //   setConnectionStatus(null);
      //   wsInstance.current = null;
      //   toast.error("Connection was lost, reconnecting...")
      //   await new Promise(resolve=>setTimeout(resolve,1500))
      // }
      // console.debug(wsInstance.current.send);
      const signature = await requestSignature();
      // console.debug(JSON.stringify(signature));
      const message = JSON.stringify({ action: "bid", itemId, userId: address, signature });
      // console.debug(JSON.stringify(message));
      // console.debug(message);
      try {
        wsInstance.current.send(message);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const auctionEnd = async (itemId) => {
    if (wsInstance.current && isConnected && wsInstance.current.readyState) {
      // if (!wsInstance.current.readyState) {
      //   setConnectionStatus("Disconnected");
      //   wsInstance.current = null;
      //   toast.error("Connection was lost, reconnecting...")
      //   return;
      // }
      // console.debug(wsInstance.current.send);
      const signature = await requestSignature();
      // console.debug(JSON.stringify(signature));
      const message = JSON.stringify({ action: "endAuction", itemId, signature });
      // console.debug(JSON.stringify(message));
      // console.debug(message);
      try {
        wsInstance.current.send(message);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const autoBuy = async (itemId) => {
    if (wsInstance.current && isConnected && wsInstance.current.readyState && address) {
      // if (!wsInstance.current.readyState) {
      //   setConnectionStatus("Disconnected");
      //   wsInstance.current = null;
      //   toast.error("Connection was lost, reconnecting...")
      //   return;
      // }
      // console.debug(wsInstance.current.send);
      const signature = await requestSignature();
      // console.debug(JSON.stringify(signature));
      const message = JSON.stringify({ action: "autoBuy", itemId, userId: address, signature });
      // console.debug(JSON.stringify(message));
      // console.debug(message);
      try {
        wsInstance.current.send(message);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      if (!wsInstance.current.readyState) {
        setConnectionStatus("Disconnected");
        wsInstance.current = null;
        toast.error("Connection was lost, reconnecting...");
        connectWebSocket();
      } else {
        getInfo();
        // if this doesn't work save the items in localstorage and retrieve it to reconnect 
        getItems({
          status,
          category,
          search,
          itemId,
          page,
          sortType,
        });
      }
    };
    // Add the event listener when the component mounts
    window.addEventListener("focus", handleFocus);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [getInfo, getItems, category, itemId, page, search, sortType, status]);

  const connectWebSocket = useCallback(() => {
    const endpoint = process.env.REACT_APP_WS_PENNY_AUCTION_URL;

    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    const reconnectDelay = 2000; // 2 seconds
    // Create WebSocket connection when the component mounts

    if (!wsInstance.current && reconnectAttempts < maxReconnectAttempts) {
      wsInstance.current = new WebSocket(endpoint);

      wsInstance.current.onopen = () => {
        setConnectionStatus("Connected");
        iddleIntervalRef.current = setInterval(
          () => {
            getInfo();
          },
          5 * 60 * 1000
        ); // 5 minutes)
        reconnectAttempts = 0; // Reset on successful connection
        getInfo();
        getItems({
          status,
          category,
          search,
          itemId,
          page,
          sortType,
        });
      };
      wsInstance.current.onmessage = (event) => {
        // console.debug({ event });
        try {
          const response = JSON.parse(event.data);
          if (response.status == 200 || response.status == 201) {
            // console.debug("SUCCESS", { response });
            switch (response.action) {
              case "items": {
                handleItemsResponse({
                  data: response.data,
                  setItems,
                  setPagination,
                  setServerTime,
                });
                // setInfo(response.data);
                break;
              }
              case "item": {
                handleItemResponse({ data: response.data, setItem: setItemDetails });
                // setInfo(response.data);
                break;
              }
              case "users": {
                handleUserResponse({ data: response.data, setUser, setServerTime });
                break;
              }
              case "bid": {
                // setInfo(response.data);
                handleBidsResponse({
                  data: response.data,
                  setItems,
                  setItemDetails,
                  itemDetails: itemDetailsRef.current,
                  setServerTime,
                });
                break;
              }
              case "userBid": {
                // setInfo(response.data);
                handleUserBidsResponse({
                  data: response.data,
                  setItems,
                  setItemDetails,
                  itemDetails: itemDetailsRef.current,
                  setUser,
                  setServerTime,
                });
                break;
              }
              case "auctionEnd": {
                // setInfo(response.data);
                handleAuctionEndResponse({
                  data: response.data,
                  setItems,
                  setItemDetails,
                  itemDetails: itemDetailsRef.current,
                });
                getInfo();
                break;
              }
              case "auctionWin": {
                // setInfo(response.data);
                handleAuctionWinResponse({
                  data: response.data,
                  setItems,
                  setItemDetails,
                  itemDetails: itemDetailsRef.current,
                });
                toast.success(
                  `Congratulations! Auction Won: ${
                    response.data && response.data.info && response.data.info.name
                      ? response.data.info.name
                      : ""
                  }`
                );
                break;
              }
              case "autoBuy": {
                // setInfo(response.data);
                handleAutobuyResponse({
                  data: response.data,
                  setItems,
                  setItemDetails,
                  itemDetails: itemDetailsRef.current,
                  address,
                  updateUser: getInfo,
                });
                break;
              }
              case "userAutoBuy": {
                // setInfo(response.data);
                handleUserAutobuyResponse({
                  data: response.data,
                  setItems,
                  setItemDetails,
                  itemDetails: itemDetailsRef.current,
                });
                getInfo();
                toast.success(
                  `Congratulations! Item Bought: ${
                    response.data &&
                    response.data.item &&
                    response.data.item.info &&
                    response.data.item.info.name
                      ? response.data.item.info.name
                      : ""
                  }`
                );
                break;
              }
              case "serverTime": {
                // setInfo(response.data);
                handleServerTimeResponse({
                  data: response.data,
                  setServerTime,
                });
                break;
              }
              case "message":
                setMessage((prev) => [...prev, response.data]);
                break;
            }
          } else {
            toast.error(response.error);
          }
        } catch (error) {
          toast.error(error);
          console.error(error);
        }
      };
      wsInstance.current.onerror = () => {
        // console.log(wsInstance.current);
        setConnectionStatus(null);
        wsInstance.current = null;
        reconnectAttempts += 1;
        clearInterval(iddleIntervalRef.current);

        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(connectWebSocket, reconnectDelay);
        } else {
          console.error("Max reconnection attempts reached.");
        }
      };
      wsInstance.current.onclose = () => {
        if (wsInstance.current) {
          // setConnectionStatus(null);
          wsInstance.current = null;
          // reconnectAttempts += 1;
          clearInterval(iddleIntervalRef.current);
          // connectWebSocket();

          // if (reconnectAttempts < maxReconnectAttempts) {
          //   setTimeout(connectWebSocket, reconnectDelay);
          // } else {
          //   console.error("Max reconnection attempts reached.");
          // }
        }
      };
    }
  }, [getInfo, getItems, status, category, search, itemId, page, sortType, address]);

  useEffect(() => {
    // console.debug(connectionStatus);
    if (!connectionStatus) {
      // console.debug("executing connectwebsocket");
      connectWebSocket();
    }
    return () => {
      // if (wsInstance.current) {
      //   setConnectionStatus(null);
      //   wsInstance.current.close();
      //   wsInstance.current = null;
      //   clearInterval(iddleIntervalRef.current);
      //   console.debug("WebSocket cleaned up.");
      // }
    };
  }, [connectionStatus, connectWebSocket]); // Empty dependency array ensures the effect runs only once, on component mount
  // if (wsInstance) {
  //   console.debug("wsInstance.current", wsInstance.current);
  // }
  const handleSearchOptions = useCallback(
    ({ newCategory, newSearch, newItemId, newStatus, newPage, newSortType }) => {
      // console.debug("handleSearchOptions", {
      //   newCategory,
      //   newSearch,
      //   newItemId,
      //   newStatus,
      //   newPage,
      // });
      if (newCategory === "categories") newCategory = null;
      const categoryToFetch = newCategory !== undefined ? newCategory : category ?? null;
      const searchToFetch = newSearch !== undefined ? newSearch : search ?? null;
      const itemIdToFetch = newItemId !== undefined ? newItemId : itemId ?? null;
      const statusToFetch = newStatus !== undefined ? newStatus : status ?? null;
      const sortTypeToFetch = newSortType !== undefined ? newSortType : sortType ?? null;
      const newPageToFetch =
        newCategory || newSearch || newItemId || newStatus || newSortType
          ? 1
          : newPage != undefined
          ? newPage
          : page ?? null;
      setCategory(categoryToFetch);
      setSearch(searchToFetch);
      setItemId(itemIdToFetch);
      setStatus(statusToFetch);
      setPage(newPageToFetch);
      setSortType(sortTypeToFetch);
      if (newItemId) {
        getItems({ itemId: newItemId });
      } else {
        getItems({
          category: categoryToFetch,
          search: searchToFetch,
          itemId: itemIdToFetch,
          status: statusToFetch,
          page: newPageToFetch,
          sortType: sortTypeToFetch,
        });
      }
    },
    [getItems, category, itemId, search, status, page, sortType]
  );

  const handleFollowClick = async ({ itemId }) => {
    if (!itemId) return;

    if (wsInstance.current && false) {
      // if (!wsInstance.current.readyState) {
      //   setConnectionStatus("Disconnected");
      //   wsInstance.current = null;
      //   toast.error("Connection was lost, reconnecting...")
      //   return;
      // }
      const signature = await requestSignature();
      const message = JSON.stringify({ action: "users", itemId, userId: address, signature });
      // console.debug(JSON.stringify(message));
      // console.debug(message);
      try {
        wsInstance.current.send(message);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <AuctionContext.Provider
      value={{
        getInfo,
        addBid,
        autoBuy,
        items,
        categoriesList,
        isLoadingCategories,
        categoriesListError,
        category,
        search,
        itemId,
        page,
        getItems,
        getUser,
        status,
        user,
        connectionStatus,
        handleSearchOptions,
        pagination,
        address,
        isConnected,
        auctionEnd,
        websocketInstance: wsInstance.current, //websocket instance to get crrent status is websocetInstance?.readyStatus
        handleFollowClick,
        itemDetails,
        SORT_TYPES: pennyAuctionConstants.SORT_TYPES,
        sortType,
        serverTime,
      }}
    >
      {children}
    </AuctionContext.Provider>
  );
};
