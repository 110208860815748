import { IsUserFastStartElegible } from "./getUserLastFastStartDate";

const getFastStartElegibleAffiliates = async (affiliates, contractFunctions) => {
  const elegibleAffiliates = await Promise.all(
    affiliates.map(async (aff) => {
      const userInfo = await contractFunctions.fortuneGoat.fortuneGoatUserInfo(aff);
      const isElegible = IsUserFastStartElegible({
        registrationDate: userInfo[0].registrationDate,
      });
      if (isElegible && isElegible.status) {
        return aff;
      }
      return "";
    })
  );

  const filtered = elegibleAffiliates.filter((a) => a);
  return filtered;
};

const levelAddressesPromises = async ({ addressToGet, contractFunctions }) => {
  console.debug("levelAddressesPromises", { addressToGet });
  await new Promise((resolve) => setTimeout(resolve, 50));
  const sponsored = await contractFunctions.unilevel.unilevelUsersSponsoredBy(addressToGet);
  const validSponsors = await Promise.all(
    sponsored.map(async (affiliate) => {
      const affiliateMembership = await contractFunctions.membership.membershipMap({
        id: affiliate.id,
      });
      console.debug({ affiliate });
      const affiliateAffiliates = [affiliate.id];
			if (affiliateMembership && (affiliateMembership.id == 0 || !affiliateMembership.status)) {
        const underAffiliates = (
          await levelAddressesPromises({ addressToGet: affiliate.id, contractFunctions })
        ).flatMap((a) => a);
        affiliateAffiliates.push(...underAffiliates);
      }

      const affiliatesList = await getFastStartElegibleAffiliates(
        affiliateAffiliates,
        contractFunctions
      );
      return affiliatesList;
    })
  );
  return validSponsors.flatMap((a) => a).filter(a=>a.length>0);
};

export const getLevelAddresses = async ({ addresses, contractFunctions }) => {
  console.log("getLevelAddresses faststart: ", addresses);
  addresses = addresses.filter((v) => v);
  const sponsoredList = [];
  if (!addresses || addresses.length == 0) return [];
  for (const currentAddress of addresses) {
    const packagex = await levelAddressesPromises({
      addressToGet: currentAddress,
      contractFunctions,
    });
    if (packagex) sponsoredList.push(packagex);
  }

  return sponsoredList.flatMap((v) => v);
};
