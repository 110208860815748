import { Input } from "common/components";
import { Button } from "common/components";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { AppLayout } from "layouts/AppLayout";
import { useState } from "react";
import * as yup from "yup";
import { useYupValidationResolver } from "@src/common/hooks/useYupValidationResolver";
import { useAccount } from "wagmi";
import { useForm } from "react-hook-form";
import { Select } from "common/components";
// import { onWriteContract } from "common/services";
// import ABI from "common/contracts/FortuneGoat.json";
import { toast } from "react-toastify";
import { PhoneInput } from "common/components/PhoneInput";
import { FundsForm } from "modules/admin/components/Funds/FundsForm";
import { useRequestUpdateUserInfo } from "common/hooks/useRequestUpdateUserInfo";
import { FundsUSDTsForm } from "modules/admin/components/Funds/FundsUSDTsForm";
import { FundsBidsForm } from "modules/admin/components/Funds/FundsBidsForm";
import { Modal } from "@mui/material";
import { useUpdateUserStatus } from "common/hooks/useUpdateUserStatus";
import { useNavigate } from "react-router-dom";

export const ProfileDashboardPage = () => {
  const { address } = useAccount();
  const { requestUpdateUserInfo } = useRequestUpdateUserInfo();
  // const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const { userInfo, mutateAsync } = useGetUserInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [active, setActive] = useState();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const { requestUpdateUserStatus } = useUpdateUserStatus();
  const navigate = useNavigate();
  // const [userData, setUserData] = useState(userInfo?.info || {});

  // useEffect(() => {
  //   if (userInfo) {
  //     setUserData(userInfo.info);
  //   }
  // }, [userInfo]);

  const validationSchema = yup.object({
    name: yup.string().min(3),
    email: yup.string().min(5).email(),
    country: yup.string(),
    zipcode: yup.string().min(4).max(5),
    phoneInput: yup.string().min(8).max(10),
    prefix: yup.string().min(1).max(5),
  });
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    clearErrors,
    setError,
  } = useForm({ resolver });

  const handleDeleteAccount = async () => {
    setIsDeletingAccount(true);
    await requestUpdateUserStatus({
      userData: {
        userId: address,
        isOrganizationUser: false,
        status: "INACTIVE",
        onSuccess: async () => {
          setIsDeleteAccountModalOpen(false);
          setIsDeletingAccount(false);
          toast.success("Your account has been deleted");
          navigate("/logout");
        },
        onError: async () => {
          toast.error("Something went wrong, please try again.");
          setIsDeletingAccount(false);
        },
      },
    });
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    let phone = userInfo.info.phone;
    if (data.prefix || data.phoneInput) {
      if (!data.prefix || data.prefix === "-")
        setError("prefix", { type: "custom", message: "Select one prefix" });
      if (!data.phoneInput)
        setError("phoneInput", { type: "custom", message: "Phone number cannot be empty" });
      phone = `${data.prefix}${data.phoneInput}`;
    }
    let parsedInputs = {
      name: data.name || userInfo.info.name || "",
      lastName: data.name || userInfo.info.name || "",
      email: data.email || userInfo.info.email || "",
      country: data.country || userInfo.info.country || "",
      zip: data.zipcode || Number(userInfo.info.zipcode) || "",
      phone: phone || userInfo.info.phone || "",
      registerDate: Number(userInfo.info.registerDate) || 0,
    };

    await requestUpdateUserInfo({
      userData: {
        ...{ ...parsedInputs, userId: address },
        onSuccess: async () => {
          setValue(active, undefined);
          setActive();
          await mutateAsync();
          toast.success("Your information has been updated");
          setIsSubmitting(false);
        },
        onError: async () => {
          toast.error("Something went wrong, please try again.");
          setIsSubmitting(false);
        },
      },
    });
  };

  // const updateResponse = await onWriteContract({
  //   contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
  //   contractABI: ABI,
  //   functionName: "updateUserInfo",
  //   args: [
  //     address,
  //     parsedInputs.name,
  //     parsedInputs.lastName,
  //     parsedInputs.email,
  //     parsedInputs.country,
  //     parsedInputs.zipcode,
  //     parsedInputs.phone,
  //     parsedInputs.registerDate,
  //   ],
  //   from: address,
  // });
  // if (updateResponse) {
  //   setValue(active, undefined);
  //   setActive();
  //   toast.success("Your information has been updated");
  //   await mutateAsync();
  // } else {
  //   toast.error("Something went wrong, please try again.");
  // }

  const handleClick = (e) => {
    e.preventDefault();
    if (active !== e.target.name) {
      setValue(active, undefined);
      clearErrors(active);
      setActive(e.target.name);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleCancel = () => {
    setValue(active, undefined);
    clearErrors(active);
    setActive();
  };

  return (
    <AppLayout>
      <div className="grid grid-cols-2 px-8 py-4 gap-y-4">
        <div className="col-span-2 bg-white px-8 py-4">
          <h2 className="font-bold text-2xl pb-8">YOUR ACCOUNT</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-2 border-y-2 border-gray-200 md:h-24 items-center md:grid md:grid-cols-2 md:ml-4">
              <div>
                <p className="font-semibold">NAME</p>
                {active === "name" ? (
                  <>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      className="mr-2"
                      placeholder={userInfo && userInfo.info.name}
                      {...register("name")}
                      onBlur={() => trigger("name")}
                    />
                    {errors.name && <span className="text-red-500">{errors.name.message}</span>}
                  </>
                ) : (
                  <p className="py-2">{userInfo && userInfo.info.name}</p>
                )}
              </div>
              <div>
                {active === "name" && (
                  <Button
                    className="w-1/2 md:mr-2 md:w-1/4"
                    label="CANCEL"
                    onClick={handleCancel}
                  />
                )}
                <Button
                  className={active === "name" ? "w-1/2 md:w-1/4" : "w-full md:w-1/4"}
                  name="name"
                  disabled={isSubmitting && active === "name"}
                  label={
                    isSubmitting && active === "name"
                      ? "SAVING..."
                      : active === "name"
                      ? "SAVE"
                      : "EDIT"
                  }
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="col-span-2 border-b-2 border-gray-200 md:h-24 items-center md:grid md:grid-cols-2 md:ml-4">
              <div>
                <p className="font-semibold">EMAIL ADDRESS</p>
                {active === "email" ? (
                  <>
                    <Input
                      name="email"
                      id="email"
                      className="mr-2"
                      type="text"
                      placeholder={userInfo && userInfo.info.email}
                      {...register("email")}
                      onBlur={() => trigger("email")}
                    />
                    {errors.email && <span className="text-red-500">{errors.email.message}</span>}
                  </>
                ) : (
                  <p className="py-2">{userInfo && userInfo.info.email}</p>
                )}
              </div>
              <div>
                {active === "email" && (
                  <Button
                    className="w-1/2 md:mr-2 md:w-1/4"
                    label="CANCEL"
                    onClick={handleCancel}
                  />
                )}
                <Button
                  className={active === "email" ? "w-1/2 md:w-1/4" : "w-full md:w-1/4"}
                  name="email"
                  disabled={isSubmitting && active === "email"}
                  label={
                    isSubmitting && active === "email"
                      ? "SAVING..."
                      : active === "email"
                      ? "SAVE"
                      : "EDIT"
                  }
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="col-span-2 border-b-2 border-gray-200 md:h-24 items-center md:grid md:grid-cols-2 md:ml-4">
              <div>
                <p className="font-semibold">PHONE NUMBER</p>
                {active === "phone" ? (
                  <PhoneInput
                    value={watch("phoneInput")}
                    setValue={setValue}
                    errors={errors}
                    clearErrors={clearErrors}
                    placeholder={userInfo && userInfo.info.phone}
                  />
                ) : (
                  <p className="py-2">{userInfo && userInfo.info.phone}</p>
                )}
              </div>
              <div>
                {active === "phone" && (
                  <Button
                    className="w-1/2 md:mr-2 md:w-1/4"
                    label="CANCEL"
                    onClick={handleCancel}
                  />
                )}
                <Button
                  className={active === "phone" ? "w-1/2 md:w-1/4" : "w-full md:w-1/4"}
                  name="phone"
                  disabled={isSubmitting && active === "phone"}
                  label={
                    isSubmitting && active === "phone"
                      ? "SAVING..."
                      : active === "phone"
                      ? "SAVE"
                      : "EDIT"
                  }
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="col-span-2 border-b-2 border-gray-200 md:h-24 items-center md:grid md:grid-cols-2 md:ml-4">
              <div>
                <p className="font-semibold">COUNTRY</p>
                {active === "country" ? (
                  <Select
                    name="country"
                    id="country"
                    className="mr-2"
                    type="text"
                    options={["Costa Rica", "México"]}
                    {...register("country")}
                    onBlur={() => trigger("country")}
                  />
                ) : (
                  <p className="py-2">{userInfo && userInfo.info.country}</p>
                )}
              </div>
              <div>
                {active === "country" && (
                  <Button
                    className="w-1/2 md:mr-2 md:w-1/4"
                    label="CANCEL"
                    onClick={handleCancel}
                  />
                )}
                <Button
                  className={active === "country" ? "w-1/2 md:w-1/4" : "w-full md:w-1/4"}
                  name="country"
                  disabled={isSubmitting && active === "country"}
                  label={
                    isSubmitting && active === "country"
                      ? "SAVING..."
                      : active === "country"
                      ? "SAVE"
                      : "EDIT"
                  }
                  onClick={handleClick}
                />
              </div>
            </div>
            <div className="col-span-2 border-b-2 border-gray-200 md:h-24 items-center md:grid md:grid-cols-2 md:ml-4">
              <div>
                <p className="font-semibold">ZIP</p>
                {active === "zipcode" ? (
                  <>
                    <Input
                      name="zipcode"
                      id="zipcode"
                      className="mr-2"
                      type="text"
                      placeholder={userInfo && userInfo.info.zipcode}
                      {...register("zipcode")}
                      onBlur={() => trigger("zipcode")}
                    />
                    {errors.zipcode && (
                      <span className="text-red-500">{errors.zipcode.message}</span>
                    )}
                  </>
                ) : (
                  <p className="py-2">{userInfo && parseInt(userInfo.info.zipcode)}</p>
                )}
              </div>
              <div>
                {active === "zipcode" && (
                  <Button
                    className="w-1/2 md:mr-2 md:w-1/4"
                    label="CANCEL"
                    onClick={handleCancel}
                  />
                )}
                <Button
                  className={active === "zipcode" ? "w-1/2 md:w-1/4" : "w-full md:w-1/4"}
                  name="zipcode"
                  disabled={isSubmitting && active === "zipcode"}
                  label={
                    isSubmitting && active === "zipcode"
                      ? "SAVING..."
                      : active === "zipcode"
                      ? "SAVE"
                      : "EDIT"
                  }
                  onClick={handleClick}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-2 bg-white min-h-[600px] px-8 hidden">
          <p className="font-semibold text-xl py-4">ID VERIFICATION</p>
          <p className="font-semibold text-xl py-4">IMAGE VERIFICATION</p>
          <p className="mb-8">
            Let us get to know you through these images, so we can verify your data.
          </p>
          <div className="grid grid-cols-3 w-full gap-4">
            <div className="col-span-3 md:col-span-1 bg-gray-100 p-4">
              <p className="font-semibold">Front ID image</p>
              <div className="bg-gray-200 w-full h-40"></div>
            </div>
            <div className="col-span-3 md:col-span-1 bg-gray-100 p-4">
              <p className="font-semibold">ID from behind</p>
              <div className="bg-gray-200 w-full h-40"></div>
            </div>
            <div className="col-span-3 md:col-span-1 bg-gray-100 p-4">
              <p className="font-semibold">Photo of you with your ID</p>
              <div className="bg-gray-200 w-full h-40"></div>
            </div>
            <div className="col-span-3 p-4 flex justify-end">
              <button className="bg-gray-200 text-gray-600 font-semibold py-2 px-4">CANCEL</button>
              <button className="bg-highlight-secondary text-gray-600 font-semibold py-2 px-4 ml-2">
                SAVE
              </button>
            </div>
          </div>
          <p className="font-semibold text-xl py-4">PROOF OF ADDRESS</p>
          <p>
            Please provide a proof of address, upload either a utility bill, credit card statement,
            lease agreement or mortgage statement.
          </p>
          <div className="border border-dashed border-gray-300 my-10">
            <p className="text-center text-xl font-bold pt-4">Drag and drop here</p>
            <p className="text-center">or</p>
            <p className="text-center pb-4 text-[#87BAD7]">Browse files</p>
          </div>
          <p className="font-semibold text-base py-4">UPLOADED FILES</p>
          <div className="bg-gray-200 my-4 h-20" />
        </div>
        <div className="col-span-2 bg-white px-8 py-4">
          <p className="font-semibold text-xl py-4">SECURITY</p>
          <div className="hidden">
            <p className="uppercase font-semibold">sign out from all devices</p>
            <p>
              Logged in on a shared device but forgot to sign out? End all sessions by signing out
              from all devices.
            </p>
            <button className="bg-highlight-secondary text-gray-600 font-semibold py-2 px-4 mt-4">
              SIGN OUT FROM ALL DEVICES
            </button>
            <hr className="my-8" />
          </div>
          <p className="uppercase font-semibold">DELETE ACCOUNT</p>
          <p>
            By deleting your account, you will no longer be able to access any of your events or log
            in to FortuneGoat.
          </p>
          <button
            onClick={() => setIsDeleteAccountModalOpen(true)}
            className="bg-highlight-secondary text-gray-600 font-semibold py-2 px-4 mt-4"
          >
            DELETE ACCOUNT
          </button>
        </div>
      </div>
      <div className="my-8 grid grid-cols-2 gap-4 px-8">
        <div className="col-span-2 md:col-span-1" id="transferMATIC">
          <FundsForm />
        </div>
        <div className="col-span-2 md:col-span-1" id="transferUSDTs">
          <FundsUSDTsForm />
        </div>
        <div className="col-span-2 md:col-span-1" id="transferBids">
          <FundsBidsForm />
        </div>
      </div>
      <Modal open={isDeleteAccountModalOpen} onClose={() => setIsDeleteAccountModalOpen(false)}>
        <div className="bg-white p-4 w-full max-w-[600px] mx-auto mt-[5%]">
          <h1 className="text-xl font-semibold">Delete Account</h1>
          <p className="py-4">
            Are you sure you want to delete your account? This action cannot be undone.
          </p>
          <div className="flex justify-end gap-x-2 mt-4">
            <button
              onClick={() => setIsDeleteAccountModalOpen(false)}
              className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
            >
              CANCEL
            </button>
            <button
              disabled={isDeletingAccount}
              onClick={handleDeleteAccount}
              className="border bg-[#FF0000] text-white font-semibold px-4 py-2"
            >
              {isDeletingAccount ? "DELETING..." : "DELETE ACCOUNT"}
            </button>
          </div>
        </div>
      </Modal>
    </AppLayout>
  );
};
