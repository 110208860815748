import { Modal } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "common/components";
import { Input } from "common/components";
import { useRequestUpdateUserInfo } from "common/hooks/useRequestUpdateUserInfo";
import { useSetRoles } from "common/hooks/useSetRoles";
import { useFetchRoles } from "modules/admin/hooks/Roles/useFetchRoles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

export const EditOrganizationUserModal = ({ userToEdit, isEditModalOpen, onClose, onUpdate }) => {
  const [activeTab, setActiveTab] = useState("personal-details");
  const activeClass = "border-b-2 border-b-[#1840CE]";
  const { requestUpdateUserInfo } = useRequestUpdateUserInfo();
  const { data: rolesData } = useFetchRoles();
  const { address } = useAccount();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, setValue, watch } = useForm();
  const { requestSetRoles } = useSetRoles();
  const queryClient = useQueryClient();

  useEffect(() => {
    rolesData &&
      rolesData.map((role) => {
        setValue(
          `admin-${role.id}`,
          userToEdit?.userRoles.some((userRole) => userRole.id === role.id)
        );
      });
  }, [rolesData, setValue, userToEdit]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const adminTrueIndexes = Object.entries(data)
      .filter(([key, value]) => key.startsWith("admin") && value === true)
      .map(([key, value]) => parseInt(key.match(/\d+$/)[0]));

    // return console.log({ adminTrueIndexes });

    if (activeTab === "permissions") {
      await requestSetRoles({
        rolesData: {
          userId: userToEdit.id,
          roles: adminTrueIndexes,
          onSuccess: async () => {
            toast.success("Roles updated successfully");
            queryClient.invalidateQueries("organizationUsers");
            onClose();
            setIsSubmitting(false);
          },
          onError: async () => {
            toast.error("Failed to update roles");
            setIsSubmitting(false);
          },
        },
      });
    }

    if (activeTab === "personal-details") {
      const formattedData = {
        isOrganizationUser: true,
        userId: userToEdit.id,
        name: data.name,
        lastName: data.lastName,
        username: data.username,
        country: data.country,
        email: data.email,
        zip: data.zipCode,
        phone: data.phone,
      };
      await requestUpdateUserInfo({
        userData: {
          ...formattedData,
          onSuccess: async () => {
            onClose();
            toast.success("User updated successfully");
            queryClient.invalidateQueries("organizationUsers");
            setIsSubmitting(false);
          },
          onError: async () => {
            toast.error("Failed to update user");
            setIsSubmitting(false);
          },
        },
      });
    }
    onUpdate();
  };

  const Tabs = (
    <div className="grid grid-cols-3 w-full">
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "personal-details" ? activeClass : ""
        }`}
        onClick={() => setActiveTab("personal-details")}
      >
        Personal details
      </div>
      <div
        className={`flex border-b-2 justify-center p-4 cursor-pointer ${
          activeTab === "permissions" ? activeClass : ""
        }`}
        onClick={() => setActiveTab("permissions")}
      >
        Permissions
      </div>
    </div>
  );

  const PersonalDetails = (
    <div className="grid grid-cols-2 gap-x-2 gap-y-4 mt-4">
      <Controller
        name="name"
        control={control}
        defaultValue={userToEdit?.name}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="text"
            name="name"
            id="name"
            className="border p-2"
            label="Name"
          />
        )}
      />
      {/* <Input
        type="text"
        name="lastName"
        id="lastName"
        className="border p-2"
        label="Last Name"
        defaultValue={userToEdit?.lastname}
      /> */}
      <Controller
        name="lastName"
        control={control}
        defaultValue={userToEdit?.lastname}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="text"
            name="lastName"
            id="lastName"
            className="border p-2"
            label="Last Name"
          />
        )}
      />
      {/* <Input
        type="text"
        name="username"
        id="username"
        className="border p-2"
        label="Username"
        defaultValue={userToEdit?.username}
      /> */}
      <Controller
        name="username"
        control={control}
        defaultValue={userToEdit?.username}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="text"
            name="username"
            id="username"
            className="border p-2"
            label="Username"
          />
        )}
      />
      {/* <Input
        type="text"
        name="country"
        id="country"
        className="border p-2"
        label="Country"
        defaultValue={userToEdit?.country}
      /> */}
      <Controller
        name="country"
        control={control}
        defaultValue={userToEdit?.country}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="text"
            name="country"
            id="country"
            className="border p-2"
            label="Country"
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue={userToEdit?.email}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="email"
            name="email"
            id="email"
            className="border p-2"
            label="Email"
          />
        )}
      />
      {/* <Input
        type="text"
        name="zipCode"
        id="zipCode"
        className="border p-2"
        label="Zip Code"
        defaultValue={userToEdit?.zipcode}
      /> */}
      <Controller
        name="zipCode"
        control={control}
        defaultValue={userToEdit?.zipcode}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="text"
            name="zipCode"
            id="zipCode"
            className="border p-2"
            label="Zip Code"
          />
        )}
      />
      {/* <Input
        type="text"
        name="phone"
        id="phone"
        className="border p-2"
        label="Phone"
        defaultValue={userToEdit?.phone}
      /> */}
      <Controller
        name="phone"
        control={control}
        defaultValue={userToEdit?.phone}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            type="text"
            name="phone"
            id="phone"
            className="border p-2"
            label="Phone"
          />
        )}
      />
    </div>
  );

  const Permissions = (
    <div className="flex gap-x-2 mt-4 flex-col">
      {rolesData &&
        rolesData.map((role) => (
          <Controller
            key={role.id}
            name={`admin-${role.id}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="flex w-full justify-between">
                <label htmlFor={`admin-${role.id}`} className="text-dark font-semibold pb-2">
                  {role.name}
                </label>
                <input
                  key={Math.random()}
                  checked={value || false}
                  onChange={(e) => onChange(e.target.checked)}
                  type="checkbox"
                  id={`admin-${role.id}`}
                  className="border p-2"
                />
              </div>
            )}
          />
        ))}
      {/* <Select name="role" id="role" label="Role" options={["Super admin", "User managment"]} /> */}
    </div>
  );

  return (
    <Modal open={isEditModalOpen} onClose={() => onClose()}>
      <div className="bg-white p-4 w-full max-w-[600px] mx-auto mt-[5%]">
        <h1 className="text-xl font-semibold">Edit &quot;{userToEdit?.name}&quot;</h1>
        {Tabs}
        {activeTab === "personal-details" ? PersonalDetails : Permissions}
        <div className="flex justify-end gap-x-2 mt-4">
          <button
            className="border bg-[#CCCCCC] text-[#051F40] font-semibold px-4 py-2"
            onClick={() => onClose(false)}
          >
            Cancel
          </button>
          <button
            className="border bg-[#BCE210] text-[#051F40] font-semibold px-4 py-2"
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};
