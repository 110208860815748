import { AppLayout } from "@layouts/AppLayout";
// import WalletIcon from "@assets/icons/dashboard/wallet.svg";
// import GraphSvg from "@assets/test/graph.svg";
// import CalendarSvg from "@assets/test/calendar.svg";
import CardsSvg from "@assets/test/cards.svg";
import SocialSvg from "@assets/test/social.svg";
import LeftPoints from "@assets/icons/left-points.svg";
import LeftComission from "@assets/icons/left-comission.svg";
import RightComission from "@assets/icons/right-comission.svg";
// import MoneyIcon from "@assets/money.svg";
import WalletIcon from "@assets/icons/wallet.svg";
import ReceiptIcon from "@assets/icons/receipt.svg";
import PeopleIcon from "@assets/icons/people.svg";
import RightPoints from "@assets/icons/right-points.svg";
import UserTick from "@assets/icons/user-tick.svg";
// import { ProgressBar } from "@common/components";
// import { ActualLevel } from "common/components";
import { useEffect, useState } from "react";
import { onReadContract } from "@common/services";
import USDTABI from "@common/contracts/USDT.json";
import FortuneGoatABI from "@common/contracts/FortuneGoat.json";
import { convertBigIntToNumber } from "@common/helpers";
import { remove6Decimals } from "@common/helpers";
import { useAccount, useBalance, useEstimateFeesPerGas } from "wagmi";
import { useForm } from "react-hook-form";
import { Button } from "@common/components";
// import { Input } from "common/components";
import { torusPlugin } from "Web3AuthConnectorInstance";
import { getFiatWithFee } from "@common/helpers";
import * as yup from "yup";
import { useYupValidationResolver } from "@common/hooks/useYupValidationResolver";
import { onWriteContract } from "common/services";
import { toast } from "react-toastify";
import { onEstimateWriteContractGas } from "common/services/onEstimateGas";
import { useRequestFunds } from "common/hooks/useRequestFunds";
import { useRequestAddPayment } from "common/hooks/useRequestAddPayment";
import { SentryService } from "common/services/SentryErrorCapture";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPeopleGroup, faReceipt, faWallet } from "@fortawesome/free-solid-svg-icons";
import { ImageLevel } from "common/components";
import { useGetUserInfo } from "common/hooks/useGetUserInfo";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useFetchEvents } from "modules/admin/hooks/Calendar/useFetchEvents";
import { useFetchUserDetail } from "modules/admin/hooks/Users/useFetchUserDetail";
import { useNavigate } from "react-router-dom";
import { useGetWalletHistory } from "common/hooks/useGetWalletHistory";
import { useFetchTeamPerformance } from "common/hooks/useFetchTeamPerformance";
import { useGetUserTotalPoints } from "common/hooks/useGetUserTotalPoints";
import { useGetActiveChildren } from "common/hooks/useGetActiveChildren";
import { useFetchTotalCommissions } from "common/hooks/useFetchTotalCommissions";
import { formatNumber } from "common/util/formatNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faTwitter, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

const localizer = momentLocalizer(moment);

export const DashboardPage = () => {
  const { data: gasFeeData } = useEstimateFeesPerGas();
  const [availableInWallet, setAvailableInWallet] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const { address, isConnected } = useAccount();
  const [refresh, setRefresh] = useState(false);
  const [unilevelTreeUsers, setUnilevelTreeUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data: balanceData } = useBalance({ address: isConnected ? address : undefined });
  const { requestFunds } = useRequestFunds();
  const { requestAddPayment } = useRequestAddPayment();
  const { userInfo } = useGetUserInfo();
  const navigate = useNavigate();
  const { data: totalCommissionsData } = useFetchTotalCommissions("totalCommissions", address);

  const { data: eventsData } = useFetchEvents();

  const { data: walletHistoryData } = useGetWalletHistory("walletHistory", address);
  const { data: teamPerformanceData } = useFetchTeamPerformance("teamPerformance", address);
  const { data: totalUserPoints, loading: loadingTotalUserPoints } = useGetUserTotalPoints(
    "userPoints",
    address
  );
  const { activeChildren, loading: loadingActiveChildren } = useGetActiveChildren(address);

  const minAmount = 1;

  const handleCopyText = () => {
    navigator.clipboard.writeText(
      `https://fortunegoat.com/join?sponsor=${
        userInfo && userInfo.info.username.replace(/ /g, "_")
      }`
    );
    toast.success("Copied to clipboard");
  };

  const validationSchema = yup.object({
    amount: yup
      .number()
      .required("Amount is required")
      .min(minAmount, "Amount should be greater than 1"),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const { handleSubmit, watch, setValue, reset } = useForm({
    resolver,
  });

  const handleAmountInput = (e) => {
    const value = e.target.value;
    e.target.value = value.replace(/^-|[.,]/g, "");
  };

  const handleAmountChange = (e) => {
    if (Number(e.target.value) > 12000) {
      e.target.value = "12000";
    }
    if (e.target.value.startsWith("0")) {
      e.target.value = e.target.value.slice(1);
    }
    setValue("amount", Number(e.target.value));
  };

  const maxFiat =
    getFiatWithFee(Number(watch("amount"))) >= 500 ? 500 : getFiatWithFee(Number(watch("amount")));

  const torusRampOn = async () => {
    if (!address || !isConnected) {
      return;
    }
    if (!torusPlugin || !torusPlugin.torusWalletInstance.isInitialized) {
      return;
    }
    try {
      await torusPlugin.initiateTopup("transak", {
        selectedAddress: address,
        selectedCurrency: "USD", // Fiat currency
        fiatValue: !watch("amount") ? 500 : maxFiat, // Fiat Value
        selectedCryptoCurrency: "USDT", // Cryptocurreny `SOL`, `MATIC` etc.
        chainNetwork: "matic", // Blockchain network
      });
    } catch (error) {
      SentryService("DashboardPage", "torusRampOn", error);
      // console.log({ error });
    }
  };

  const notEnoughFunds =
    watch("amount") >= minAmount && watch("amount") > remove6Decimals(availableInWallet);

  useEffect(() => {
    const getBalance = async () => {
      const data = await onReadContract({
        contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
        contractABI: USDTABI,
        functionName: "balanceOf",
        args: [address],
      });
      if (typeof data === "bigint") {
        if (convertBigIntToNumber(data)) {
          setAvailableInWallet(convertBigIntToNumber(data));
        }
      }
    };
    const getUserPackage = async () => {
      const data = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: FortuneGoatABI,
        functionName: "getUserPackage",
        args: [address],
      });
      if (data) {
        setTotalPaid(convertBigIntToNumber(data.totalPaid) || 0);
      }
    };
    const getUnilevelTreeUsersBySponsorId = async () => {
      const data = await onReadContract({
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        contractABI: FortuneGoatABI,
        functionName: "getUnilevelTreeUsersBySponsorId",
        args: [address],
      });
      if (data) {
        setUnilevelTreeUsers(data);
      }
    };
    getUnilevelTreeUsersBySponsorId();
    getUserPackage();
    getBalance();
  }, [address, refresh]);

  const decimals = 6;

  const onSubmit = async (data) => {
    setLoading(true);
    const hasApproval = await onReadContract({
      contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
      contractABI: USDTABI,
      functionName: "allowance",
      args: [address, process.env.REACT_APP_CONTRACT_ADDRESS],
      from: address,
    });
    let approveResponse = hasApproval ? Number(hasApproval) > 0 : false;
    if (!approveResponse) {
      const estimateApprovalGas = await onEstimateWriteContractGas({
        contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
        contractABI: USDTABI,
        functionName: "approve",
        args: [process.env.REACT_APP_CONTRACT_ADDRESS, BigInt(9999999999999999)],
        from: address,
        confirmations: 2,
      });
      console.log({ estimateApprovalGas });
      console.log({
        total:
          Number(estimateApprovalGas) *
          Number(
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? gasFeeData.gasPrice || gasFeeData.maxFeePerGas
              : 10000000000
          ) *
          1.25,
      });
      const gas = parseInt(
        Number(estimateApprovalGas) *
          Number(
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? gasFeeData.gasPrice || gasFeeData.maxFeePerGas
              : 10000000000
          ) *
          2
      );
      console.log({ gas });
      if (balanceData) {
        console.log({ value: balanceData.value });
        if (Number(balanceData.value) < gas) {
          const userData = {
            address,
            onError: async () => {
              return toast.error(
                "Something went wrong funding account, please try again. FUND ERROR."
              );
            },
            onSuccess: async () => {
              console.log({ gas });
              approveResponse = await onWriteContract({
                contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
                contractABI: USDTABI,
                functionName: "approve",
                args: [process.env.REACT_APP_CONTRACT_ADDRESS, BigInt(9999999999999999)],
                from: address,
                confirmations: 2,
              });
              if (approveResponse) {
                toast.success("Successfully approved");
              } else {
                setLoading(false);
                return toast.error("Something went wrong, please try again. APPROVE ERROR 1.");
              }
              // const addPackagePaymentResponse = await onWriteContract({
              //   contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
              //   contractABI: FortuneGoatABI,
              //   functionName: "addPackagePayment",
              //   args: [address, BigInt(data.amount * 10 ** decimals)],
              //   from: address,
              // });
              console.log({ userData });
              const paymentInfo = {
                userId: address,
                amount: data.amount * 10 ** decimals,
                onSuccess: async () => {
                  toast.success("Your payment has been successfully processed!");
                  reset();
                  setValue("amount", "");
                },
                onError: async (error) => {
                  toast.error(`Something went wrong, please try again. Error: ${error}`);
                  setLoading(false);
                },
                onSettled: async () => {
                  setRefresh(!refresh);
                  setLoading(false);
                },
              };
              await requestAddPayment({ userData: paymentInfo });
            },
          };
          await requestFunds({ userData });
        } else {
          console.log({ gas });
          approveResponse = await onWriteContract({
            contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
            contractABI: USDTABI,
            functionName: "approve",
            args: [process.env.REACT_APP_CONTRACT_ADDRESS, BigInt(9999999999999999)],
            from: address,
            confirmations: 2,
          });
          if (approveResponse) {
            toast.success("Successfully approved");
          } else {
            setLoading(false);
            return toast.error("Something went wrong, please try again. APPROVE ERROR 2.");
          }
          // const addPackagePaymentResponse = await onWriteContract({
          //   contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
          //   contractABI: FortuneGoatABI,
          //   functionName: "addPackagePayment",
          //   args: [address, BigInt(data.amount * 10 ** decimals)],
          //   from: address,
          // });
          const paymentInfo = {
            userId: address,
            amount: data.amount * 10 ** decimals,
            onSuccess: async () => {
              toast.success("Your payment has been successfully processed!");
              reset();
              setValue("amount", "");
            },
            onError: async (error) => {
              toast.error(`Something went wrong, please try again. Error: ${error}`);
            },
            onSettled: async () => {
              setRefresh(!refresh);
              setLoading(false);
            },
          };
          await requestAddPayment({ userData: paymentInfo });
        }
      } else {
        setLoading(false);
      }
    } else {
      const paymentInfo = {
        userId: address,
        amount: data.amount * 10 ** decimals,
        onSuccess: async () => {
          toast.success("Your payment has been successfully processed!");
          reset();
          setValue("amount", "");
        },
        onError: async (error) => {
          toast.error(`Something went wrong, please try again. Error: ${error}`);
        },
        onSettled: async () => {
          setRefresh(!refresh);
          setLoading(false);
        },
      };
      await requestAddPayment({ userData: paymentInfo });
    }
  };

  const { data: userData } = useFetchUserDetail("currentUserDetail", { userId: address });

  const CommissionWallet = () => {
    const [walletActiveTab, setWalletActiveTab] = useState("earnings");
    return (
      <div className="col-span-6 md:col-span-3 p-4 bg-white min-h-[600px]">
        <p className="text-xl font-semibold mb-4">Comission wallet</p>
        <div className="grid grid-cols-3">
          <div
            onClick={() => setWalletActiveTab("earnings")}
            className={`flex justify-center cursor-pointer border-b-2 py-2 ${
              walletActiveTab === "earnings" && "text-[#1840CE] border-b-[#1840CE]"
            }`}
          >
            Earnings
          </div>
          <div
            onClick={() => setWalletActiveTab("expenses")}
            className={`flex justify-center cursor-pointer border-b-2 py-2 ${
              walletActiveTab === "expenses" && "text-[#1840CE] border-b-[#1840CE]"
            }`}
          >
            Expenses
          </div>
          <div
            onClick={() => setWalletActiveTab("withdrawal")}
            className={`flex justify-center cursor-pointer border-b-2 py-2 ${
              walletActiveTab === "withdrawal" && "text-[#1840CE] border-b-[#1840CE]"
            }`}
          >
            Withdrawal
          </div>
        </div>
        {walletActiveTab === "earnings" && (
          <div className="overflow-y-auto h-[500px]">
            {walletHistoryData?.income?.data.map((item, index) => (
              <div key={index} className="flex justify-between py-2 border-b">
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">Income</p>
                  <p className="text-gray-500">Payment</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">{item.value / 10 ** 6} USDT</p>
                  <p className="text-gray-500">
                    {new Date(item.timeStamp * 1000).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {walletActiveTab === "withdrawal" && (
          <div className="overflow-y-auto h-[500px]">
            {walletHistoryData?.other?.data.map((item, index) => (
              <div key={index} className="flex justify-between py-2 border-b">
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">Withdrawal</p>
                  <p className="text-gray-500">Payment</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">{item.value / 10 ** 6} USDT</p>
                  <p className="text-gray-500">
                    {new Date(item.timeStamp * 1000).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {walletActiveTab === "expenses" && (
          <div className="overflow-y-auto h-[500px]">
            {walletHistoryData?.expenses?.data.map((item, index) => (
              <div key={index} className="flex justify-between py-2 border-b">
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">Expenses</p>
                  <p className="text-gray-500">Payment</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">{item.value / 10 ** 6} USDT</p>
                  <p className="text-gray-500">
                    {new Date(item.timeStamp * 1000).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const TeamPerformance = () => {
    const [teamPerformanceActiveTab, setTeamPerformanceActiveTab] = useState("topEarners");
    return (
      <div className="col-span-6 md:col-span-3 p-4 bg-white min-h-[600px]">
        <p className="text-xl font-semibold pb-4 border-b">Team performance</p>
        <div className="grid grid-cols-3">
          <div
            onClick={() => setTeamPerformanceActiveTab("topEarners")}
            className={`flex justify-center cursor-pointer border-b-2 py-2 ${
              teamPerformanceActiveTab === "topEarners" && "text-[#1840CE] border-b-[#1840CE]"
            }`}
          >
            Top Earners
          </div>
          <div
            onClick={() => setTeamPerformanceActiveTab("packageOverview")}
            className={`flex justify-center cursor-pointer border-b-2 py-2 ${
              teamPerformanceActiveTab === "packageOverview" && "text-[#1840CE] border-b-[#1840CE]"
            }`}
          >
            Package Overview
          </div>
          <div
            onClick={() => setTeamPerformanceActiveTab("newMembers")}
            className={`flex justify-center cursor-pointer border-b-2 py-2 ${
              teamPerformanceActiveTab === "newMembers" && "text-[#1840CE] border-b-[#1840CE]"
            }`}
          >
            New Members
          </div>
        </div>
        {/* {
  "status": 200,
  "message": "Success",
  "data": {
    "topEarners": [
      {
        "userId": 3.703217048322232e+47,
        "username": "metamask",
        "commissions": {
          "total": 6199000000,
          "commissionsHistory": [
            {
              "id": 11,
              "commissionId": 11,
              "to": 3.703217048322232e+47,
              "date": 1718324018,
              "dateCounter": 1,
              "amount": 945000000,
              "status": 1,
              "statusText": "pending",
              "kind": 5,
              "kindText": "Fast start commission"
            }
          ]
        },
        "pennyAuction": {
          "items": [
            {
              "id": 192,
              "owner": 3.703217048322232e+47,
              "name": "amazon gift card",
              "description": "<p>Amazon gift card</p>",
              "timeZone": "UTC",
              "images": [
                "https://aqua-voluntary-felidae-839.mypinata.cloud/ipfs/QmSj1Rz37CYdiWzPfbZgRaWfhWUfHwp2TWDXAShJ4YrkSN"
              ],
              "category": "Gift Cards & Bid Packs",
              "message": ""
            }
          ]
        }
      }
    ],
    "packageOverview": [
      {
        "userId": 3.703217048322232e+47,
        "username": "metamask",
        "package": [
          {
            "id": 5,
            "price": 5000000000,
            "vat": "0,",
            "bidConsumable": 2000000000,
            "turboFund": 3000000000,
            "qv": 5250,
            "ucv": 1800,
            "bcv": 1400,
            "name": "DIAMOND FOUNDER,",
            "consumablePercent": 4000,
            "isUpgradePackage": false
          }
        ]
      }
    ],
    "newMembers": [
      {
        "userId": 3.703217048322232e+47,
        "username": "metamask",
        "registrationDate": 1725382476000
      }
    ]
  }
} */}
        {teamPerformanceActiveTab === "topEarners" && (
          <div className="overflow-y-auto h-[500px]">
            {teamPerformanceData?.topEarners.map((item, index) => (
              <div key={index} className="flex justify-between py-2 border-b">
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">Top Earner</p>
                  <p className="text-gray-500">Commissions</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">{item.username}</p>
                  <p className="text-gray-500">{item.commissions.total / 10 ** 6} USDT</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {teamPerformanceActiveTab === "packageOverview" && (
          <div className="overflow-y-auto h-[500px]">
            {teamPerformanceData?.packageOverview.map((item, index) => (
              <div key={index} className="flex justify-between py-2 border-b">
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">Package Overview</p>
                  <p className="text-gray-500">Package</p>
                </div>
                <div className="flex flex-col flex-1 pl-10">
                  <p className="text-[#1840CE]">{item.username}</p>
                  <p className="text-gray-500">{item.package.name}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {teamPerformanceActiveTab === "newMembers" && (
          <div className="overflow-y-auto h-[500px]">
            {teamPerformanceData?.newMembers.map((item, index) => (
              <div key={index} className="flex justify-between py-2 border-b">
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">New Members</p>
                  <p className="text-gray-500">Registration Date</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#1840CE]">{item.username}</p>
                  <p className="text-gray-500">
                    {new Date(item.registrationDate).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* <div className="flex justify-center flex-col mx-auto w-[300px] text-center mt-10">
          <p className="text-gray-500">No reports available now</p>
          <p className="text-gray-500">
            There are no records to show right now. Either you have not started yet, or change the
            filter parameters and try again.
          </p>
        </div> */}
      </div>
    );
  };

  return (
    <AppLayout>
      <div>
        <div className="bg-white px-4 py-2 w-fit items-center hidden">
          Allow Push Notifications
          <div className="border border-[#BCE210] text-[#BCE210] px-3 rounded py-1 ml-4">Allow</div>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4 my-4">
        {/* Aviable in Wallet */}
        <div className="bg-dark-blue col-span-6 md:col-span-2 flex flex-col justify-between min-h-[600px]">
          <div className="p-4">
            <h4 className="text-white font-semibold text-base">SALDO DISPONIBLE</h4>
            <h3 className="text-white font-bold text-3xl text-center pt-10">
              {remove6Decimals(availableInWallet)} USDT
            </h3>
            <div className="flex justify-center mt-4">
              <button
                className="bg-[#BCE210] text-sm text-white px-5 py-2 rounded self-center uppercase mb-10"
                onClick={() => {
                  navigate("/app/profile#transferUSDTs");
                  window.scrollTo(1900, 1900);
                }}
              >
                Transfer Funds
              </button>
            </div>
          </div>
          <div className="bg-white flex flex-col py-10">
            <div className="flex items-center">
              <div className="px-4">
                <img src={WalletIcon} className="w-10" />
              </div>
              <div className="flex flex-col px-4 py-4 justify-between w-full">
                <p className="text-[#161C24] font-semibold">Saldo pagado a Fortunegoat</p>
                <p className="text-[#BCE210]">
                  ${formatNumber(walletHistoryData?.expenses?.total || 0)}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="px-4">
                <img src={ReceiptIcon} className="w-10" />
              </div>
              <div className="flex flex-col px-4 py-4 justify-between w-full">
                <p className="text-[#161C24] font-semibold">Comisiones Calculadas</p>
                <p className="text-gray-500">${formatNumber(totalCommissionsData?.total || 0)}</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="px-4 ml-1">
                <img src={PeopleIcon} className="w-8" />
              </div>
              <div className="flex flex-col px-4 py-4 justify-between w-full">
                <p className="text-[#161C24] font-semibold">Usuarios Activos</p>
                <p className="text-[#1840CE]">{unilevelTreeUsers.length}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Binary genalogy */}
        {/* <div className="bg-dark-blue col-span-6 md:col-span-2 flex flex-col justify-between min-h-[600px]">
          <div className="p-4">
            <h4 className="text-white font-semibold text-base">Genealogía binario</h4>
            <div className="flex justify-center mt-4">
              <img src={GraphSvg} />
            </div>
            <div className="flex justify-center mt-4">
              <div className="flex items-center mr-4">
                <div className="w-3 h-3 bg-[#BCE210] rounded-full mx-2" />
                <p className="text-white text-sm">Left Sales</p>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-[#87BAD7] rounded-full mx-2" />
                <p className="text-white text-sm">Right Sales</p>
              </div>
            </div>
          </div>
          <div className="bg-white flex">
            <div className="grid grid-cols-2 gap-2 p-2 relative -top-10 w-full">
              <div className="col-span-2 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={LeftPoints} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">0</p>
                  <p className="text-gray-400 text-sm">Left points</p>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={RightPoints} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">0</p>
                  <p className="text-gray-400 text-sm">Right points</p>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={LeftComission} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">0</p>
                  <p className="text-gray-400 text-sm">Personas a la izquierda</p>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={RightComission} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">0</p>
                  <p className="text-gray-400 text-sm">Personas a la derecha</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="bg-dark-blue col-span-6 md:col-span-2 flex flex-col justify-between min-h-[600px]">
          <div className="p-4">
            <h4 className="text-white font-semibold text-base">BIDS DISPONIBLES</h4>
            <h3 className="text-white font-bold text-3xl text-center pt-10">
              {userData?.package.bids || 0} pts.
            </h3>
            <div className="flex justify-center mt-4">
              <button
                className="bg-[#BCE210] text-sm text-white px-5 py-2 rounded self-center uppercase mb-10"
                onClick={() => {
                  navigate("/app/profile#transferUSDTs");
                  window.scrollTo(2300, 2300);
                }}
              >
                Transfer Bids
              </button>
            </div>
          </div>
          <div className="bg-white flex cursor-pointer" onClick={() => navigate("/app/affiliates")}>
            <div className="grid grid-cols-2 gap-2 p-2 relative -top-10 w-full">
              <div className="col-span-1 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={LeftPoints} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">
                    {loadingTotalUserPoints ? "loading..." : totalUserPoints?.leftBranch ?? "-"}
                  </p>
                  <p className="text-gray-400 text-sm">Left points</p>
                </div>
              </div>
              <div className="col-span-1 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={RightPoints} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">
                    {loadingTotalUserPoints ? "loading..." : totalUserPoints?.rightBranch ?? "-"}
                  </p>
                  <p className="text-gray-400 text-sm">Right points</p>
                </div>
              </div>
              <div className="col-span-1 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={LeftComission} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">
                    {loadingActiveChildren ? "loading..." : activeChildren?.leftCount ?? "-"}
                  </p>
                  <p className="text-gray-400 text-sm">Personas a la izquierda</p>
                </div>
              </div>
              <div className="col-span-1 md:col-span-1 shadow-md bg-white rounded p-2">
                <div className="px-4">
                  <img src={RightComission} />
                </div>
                <div className="flex flex-col px-4 py-4 justify-between w-full">
                  <p className="text-[#161C24] font-semibold">
                    {loadingActiveChildren ? "loading..." : activeChildren?.rightCount ?? "-"}
                  </p>
                  <p className="text-gray-400 text-sm">Personas a la derecha</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Referal Link */}
        <div className="bg-white col-span-6 md:col-span-2 flex flex-col min-h-[600px]">
          <div className="w-full flex justify-center mt-10">
            <div className="p-4 border rounded border-[#EDF1F5] w-fit">
              <img src={UserTick} />
            </div>
          </div>
          <div className="w-full flex flex-col justify-center">
            <p className="text-xl py-2 text-center">Username</p>
            <p className="text-center font-bold text-xl">{userInfo && userInfo.info.username}</p>
          </div>
          <div className="w-full flex justify-center">
            <p className="py-2"></p>
          </div>
          <div className="w-full flex justify-center">
            <p className="px-2 rounded-full text-white font-semibold bg-[#BCE210]">Active</p>
          </div>
          <div className="w-full flex justify-center mt-2">
            <p className="px-2 rounded-full text-white font-semibold bg-[#87BAD7]">Member</p>
          </div>
          <div className="w-full flex justify-center mt-2 flex-col hover:">
            <p className="my-3 font-semibold text-center">Referral Link</p>
            <p className="mb-3 text-center">
              {`https://fortunegoat.com/join?sponsor=${userInfo && userInfo.info.username}`}
            </p>
          </div>
          <div className="w-full flex justify-center mt-2">
            <button
              className="px-5 py-4 rounded font-semibold bg-[#87BAD7] text-dark-blue hover:bg-[#87bad7e6]"
              onClick={() => {
                handleCopyText();
              }}
            >
              Referral Link
            </button>
          </div>
          <div className="w-full flex gap-5 justify-center mt-5">
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https://fortunegoat.com/join?sponsor=etorres"
              rel="noreferrer"
              target="_blank"
              class="share-button facebook"
            >
              <FontAwesomeIcon size="2x" color="#3b5998" icon={faFacebook} />
            </a>
            <a
              href="https://www.linkedin.com/sharing/share-offsite/?url=https://fortunegoat.com/join?sponsor=etorres"
              target="_blank"
              rel="noreferrer"
              class="share-button linkedin"
            >
              <FontAwesomeIcon size="2x" color="#0A66C2" icon={faLinkedin} />
            </a>
            <a
              href="https://wa.me/?text=Únete%20https://fortunegoat.com/join?sponsor=etorres"
              target="_blank"
              rel="noreferrer"
              class="share-button whatsapp"
            >
              <FontAwesomeIcon size="2x" color="#25D366" icon={faWhatsapp} />
            </a>
            <a
              href="https://twitter.com/intent/tweet?text=Únete%20https://fortunegoat.com/join?sponsor=etorres"
              target="_blank"
              rel="noreferrer"
              class="share-button twitter"
            >
              <FontAwesomeIcon size="2x" icon={faXTwitter} />
            </a>
          </div>
        </div>

        {/* Actual Level */}
        {/* <div className="bg-white col-span-6 grid grid-cols-4">
          <div className="col-span-4 md:col-span-1 flex flex-col p-5 bg-dark-blue">
            <ActualLevel value={remove6Decimals(totalPaid) + (watch("amount") || 0)} />
          </div>

          <div className="col-span-4 md:col-span-3">
            <ProgressBar value={remove6Decimals(totalPaid) + (watch("amount") || 0)} />
          </div>
        </div> */}

        <div className="col-span-6 grid grid-cols-4 gap-5">
          {/* Level with Image */}
          <div className="col-span-2 rounded shadow flex p-5 bg-white">
            <ImageLevel value={remove6Decimals(totalPaid) + (watch("amount") || 0)} />
          </div>

          {/* Available in Wallet */}
          {/* <div className="col-span-2 rounded shadow flex flex-col p-5 bg-white border-b border-b-[#1840CE] border-b-4">
            <img src={MoneyIcon} className="w-10" />
            <div className="flex justify-center flex-col pl-3">
              <p className="text-dark text-xl font-semibold">
                ${remove6Decimals(availableInWallet)} USDT
              </p>
              <p className="text-gray-500 text-base">Available in Wallet</p>
            </div>
          </div> */}

          {/* Make payment  */}
          {/* <div className="col-span-4 md:col-span-1 rounded shadow flex flex-col justify-center p-5 bg-white px-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              {loading ? (
                <div className="w-full text-center mb-2">
                  <div
                    class="animate-spin inline-block w-10 h-10 border-[3px] border-current border-t-transparent text-gray-400 rounded-full text-center"
                    role="status"
                    aria-label="loading"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Input
                  label="Amount"
                  type="number"
                  className="border border-dark rounded-none mb-4"
                  value={watch("amount")}
                  onChange={handleAmountChange}
                  onInput={handleAmountInput}
                  placeholder="Write amount"
                />
              )}

              <Button
                label={!loading ? "Make payment" : "Processing.."}
                type="submit"
                className="w-full text-center"
                disabled={
                  !watch("amount") ||
                  watch("amount") < minAmount ||
                  watch("amount") > remove6Decimals(availableInWallet) ||
                  loading
                }
              />
            </form>
          </div> */}

          {/*Purchase USDT */}
          <div className="col-span-2 rounded shadow flex flex-col p-5 bg-white">
            <Button
              label="Purchase USDT"
              onClick={torusRampOn}
              type="button"
              className="w-full bg-dark-blue text-white mb-4"
            />
            <img src={CardsSvg} className="max-w-[200px] w-full mx-auto" />
          </div>
        </div>

        {/* Commision Wallet */}
        <CommissionWallet />

        {/* Team performance */}
        <TeamPerformance />

        {/* Corparate Events */}
        <div className="col-span-6 md:col-span-3 p-4 bg-white min-h-[600px]">
          <p className="text-xl font-semibold pb-4 border-b">Corporate events</p>
          <div className="flex justify-center">
            {/* <img src={CalendarSvg} /> */}
            <Calendar
              localizer={localizer}
              events={
                eventsData?.events.map((event) => {
                  return {
                    id: event.id,
                    title: event.name,
                    description: event.description,
                    start: new Date(`${event.day} ${event.start}`),
                    end: new Date(`${event.day} ${event.end}`),
                  };
                }) || []
              }
              startAccessor="start"
              endAccessor="end"
              style={{ minHeight: "500px", width: "100%" }}
            />
          </div>
        </div>

        {/* News and activities */}
        <div className="col-span-6 md:col-span-3 p-4 bg-white min-h-[600px]">
          <p className="text-xl font-semibold pb-4 border-b">News and activities</p>
          <div className="flex justify-center flex-col mx-auto w-[300px] text-center mt-10">
            <p className="text-gray-500">No reports available now</p>
            <p className="text-gray-500">
              There are no records to show right now. Either you have not started yet, or change the
              filter parameters and try again.
            </p>
          </div>
        </div>
      </div>
      {/* --------- */}
      {/* <form onSubmit={handleSubmit(onSubmit)} className="my-4">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4">
            <ProgressBar value={remove6Decimals(totalPaid) + (watch("amount") || 0)} />
          </div>
          {notEnoughFunds && (
            <div className="col-span-4">
              <p className="text-red-500">
                You don&apos;t have enough funds in your wallet. Please get funds to continue.
              </p>
            </div>
          )}
          <div className="col-span-4 md:col-span-1 flex items-stretch">
            <div className="flex flex-wrap items-center justify-center border border-slate-100 rounded w-full">
              <div className="px-4 mx-4 text-2xl">{remove6Decimals(availableInWallet)}</div>
              <div className="flex flex-col text-center">
                <p>Available in wallet</p>
                <p
                  onClick={() => setRefresh(!refresh)}
                  className="text-electric-blue underline cursor-pointer"
                >
                  Update balance
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-4 md:col-span-1 flex items-stretch">
            <Button label="Get funds" onClick={torusRampOn} type="button" className="w-full" />
          </div>
          <div className="col-span-4 md:col-span-1 flex items-stretch">
            <Input
              label="Amount"
              type="number"
              className="w-full h-20"
              value={watch("amount")}
              onChange={handleAmountChange}
              onInput={handleAmountInput}
              placeholder="Enter amount"
            />
          </div>
          <div className="col-span-4 md:col-span-1 flex items-stretch">
            <Button
              label="Make payment"
              type="submit"
              className="w-full"
              disabled={
                !watch("amount") ||
                watch("amount") < minAmount ||
                watch("amount") > remove6Decimals(availableInWallet) ||
                loading
              }
            />
          </div>
        </div>
      </form> */}
    </AppLayout>
  );
};
