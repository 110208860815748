export const getLevelSum = async ({ addresses, contractFunctions }) => {
  if (!addresses || addresses.length == 0) return { sum: 0, affiliates: [] };
  // console.log("getLevelSum", addresses.length);

  const usersPayments = await Promise.all(
    addresses.map(async (address) => {
      const userPayments = await contractFunctions.userPoints.getUserPayments({ address });
      return userPayments;
    })
  );

  // console.log("usersPackages", usersPackages);
  let sum = 0;
  let affiliates = [];
  usersPayments.forEach((element) => {
		if(element.total > 0){
			sum += element.total;
			affiliates.push(element.affiliates.filter(a=>a))
		};
  });

  return { sum, affiliates };
};
