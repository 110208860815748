import { useQuery } from "@tanstack/react-query";
import { userPointsService } from "common/services/userPoints";

export const useGetUserTotalPoints = (queryKey = "userPoints", userAddress) => {

    const { fetchUserPoints } = userPointsService();

    return useQuery({
        queryKey: [queryKey],
        queryFn: () => fetchUserPoints(userAddress),
        select: (data) => data.data,
    });
};